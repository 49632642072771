import './welcome.css';
import '../Global/css/leftSide.css';
import LeftSide from '../Global/components/leftSide.js';
import { MiniLogo, PageTitle} from '../Global/components/reusable';
import { useHistory } from 'react-router-dom';
import 'bootstrap';
import { API, SessionVars } from '../Global/components/utils';
import { useEffect } from 'react';
import { getCardPrince } from '../Global/components/utils';

function WelcomePage() {
    return (
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide/>
        </div>
        );
}

function RightSide() {
    const history = useHistory();

    function redirect(){
        
        if(localStorage.getItem(SessionVars.appName) !== "" && localStorage.getItem(SessionVars.password) !== null
            && localStorage.getItem(SessionVars.password) !== "null")
            history.push("/pinCheck");
        else
            history.push("/signin");
    }
    
    function GetCardPrice(){
        sessionStorage.setItem(SessionVars.cardPrice, getCardPrince());
        
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        GetCardPrice();
    }, [])

    return (
        <div className="col-lg-7 p-0 m-0 d-flex right">
            <div className="d-flex flex-column align-self-stretch align-content-center align-content-lg-left justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>
                    
                    <div className="page-title-container">
                        <PageTitle
                            title="The Official Wolves Fan Card"
                            message="Rewards for your Loyality"/>
                    </div>
                </div>

                <div className="buttons-container d-flex flex-column align-items-center my-4">
                    <div className="mb-5 w-100">
                        {/* <div className="row px-2 mb-3">
                            <button className="add_card">
                                <span>Add a Card</span>
                            </button>
                        </div>

                        <div className="d-flex separator">
                            <div className="col-4 d-flex align-content-center">
                                <div className="separator1"/>
                            </div>
                            <div className="text col-4 d-flex justify-content-center"><p>Or Sign In</p></div>
                            <div className="col-4 d-flex justify-content-center">
                                <div className="separator2"/>
                            </div>
                        </div>  
                        */}
                        <div className="row px-0 my-3">
                            <button onClick = {() => redirect()} className="signin w-100">
                                <span>Sign In</span>
                            </button>
                        </div>
                        
                        <div className="row px-0">
                            <button
                                onClick={() => history.push("/signUp")} 
                                className="signup w-100">
                                <span>Sign Up</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="store-container my-3">
                    <p className="download-text text-center">Download the app</p>
                    <div className="icons-container">
                        <img src={process.env.PUBLIC_URL + "/Images/Icons/app-store.png"} alt="img"/>
                        <img src={process.env.PUBLIC_URL + "/Images/Icons/google-play.png"} alt="img"/>
                    </div>
                    <p className="power-text text-center">Powered by RepX</p>
                </div>
            </div>
        </div> 
    );
}

export default WelcomePage;