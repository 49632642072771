import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CardPage from '../Card/card';
import { Header } from '../Global/components/reusable';
import { API, BankAccount, SessionVars, TokenData, TokenValidation } from '../Global/components/utils';
import './bankInfo.css';

export function BankInfoPage(){
    const history = useHistory();
    const [iban, setIban] = useState("");
    const [accountNumber, setAccountNumber] = useState(null);
    const [sortCode, setSortCode] = useState(null);
    const [bicSwift, setBicSwift] = useState("");
    const [timerId, setTimerId] = useState(null);
    const [activeItem, setActiveItem] = useState("");
    const [infoToDisplay, setInfoToDisplay] = useState("");
    const [height, setHeight] = useState();

    const ref = useRef();
    const ref2 = useRef();

    function HandleResize(){
        setHeight(window.innerHeight - ref.current.offsetTop - ref2.current.offsetTop - 20);
    }

    useEffect(() => {
        var timeout = setTimeout(() => HandleResize());
        window.addEventListener("resize", HandleResize);

        return () => {clearTimeout(timeout); window.removeEventListener("resize", HandleResize)}
    }, [])

    useEffect(async () => {
        if(sessionStorage.getItem(BankAccount.iban) === null){
            if(await TokenValidation()){
                axios.get(API.bankAccountUrl, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                })
                .then(res => {
                    setIban(res.data.iban);
                    setAccountNumber(res.data.ukAccountNumber);
                    setSortCode(res.data.ukSortCode);
                    setBicSwift(res.data.bicSwift);
                })
                .catch(err => {
                    console.log(err.response);
                })
            }
            else{
                history.replace("/pinCheck");
            }
        }
        else{
            setSortCode(sessionStorage.getItem(BankAccount.sortCode));
            setAccountNumber(sessionStorage.getItem(BankAccount.accountNumber));
            setIban(sessionStorage.getItem(BankAccount.iban));
            setBicSwift(sessionStorage.getItem(BankAccount.bicSwift));
        }
    }, [])

    function CopyToClipboard(data, id){
        if(timerId !== null)
            clearTimeout(timerId);
        
        switch(id){
            case "Iban":{
                setActiveItem("Iban");
                setInfoToDisplay("Iban");
                break;
            }

            case "Account number": {
                setActiveItem("Account number");
                setInfoToDisplay("Account number");
                break;
            }

            case "Sort code":{
                setActiveItem("Sort code");
                setInfoToDisplay("Sort code");
                break;
            }

            case "Bic swift":{
                setActiveItem("Bic swift");
                setInfoToDisplay("Bic swift");
                break;
            }
        }

        const elem = document.getElementById("infoPopup")
        if(elem){
            elem.classList.add("info-popup-active");
        }
        setTimerId(setTimeout(() => {
            if(elem){
                elem.classList.remove("info-popup-active");
            }
            setActiveItem("");
            setTimerId(null);
        }, 3000))

        navigator.clipboard.writeText(data);
    }

    return(
        <div ref={ref2} className="bank-info-page">
            <div id="infoPopup" className="info-popup">
                Your {infoToDisplay.toUpperCase()} has been copied to clipboard
            </div>
            <Header text="Bank Information"/>
            <div ref={ref} style={{height: height}} className="bank-info-container">
                <BankInfo 
                    title="Iban"
                    data={iban} 
                    active={activeItem}
                    handleClick={(val, id) => CopyToClipboard(val, id)}/>

                <BankInfo 
                    title="Bic swift" 
                    data={bicSwift} 
                    active={activeItem}
                    handleClick={(val, id) => CopyToClipboard(val, id)}/>
                    
                {accountNumber !== "null" && (
                    <BankInfo 
                        title="Account number" 
                        data={accountNumber} 
                        active={activeItem}
                        handleClick={(val, id) => CopyToClipboard(val, id)}/>
                )}

                {sortCode !== "null" && (
                    <BankInfo 
                        title="Sort code" 
                        data={sortCode} 
                        active={activeItem}
                        handleClick={(val, id) => CopyToClipboard(val, id)}/>
                )}
                
            </div>
        </div>
    );
}

export function BankInfoPageStandalone(){
    return(
        <div>
            <CardPage page="Bank Info"/>
        </div>
    );
}

function BankInfo({ title= "Iban", data="GB43TEST66816357675851", handleClick = (val, id) => {}, active}){
        return(
        <div style={{margin: '10px 0'}}>
            <div className="title">
                {title}
            </div>
           
            <div className="bank-info-input-container">
                <span>
                    {data !== null ? data: "some data to dispaly"}
                </span>
                <img
                    onClick={() => handleClick(data, title)} 
                    src={active === title ? process.env.PUBLIC_URL + "/Images/Icons/" + "copy clipboard active.png" :
                                  process.env.PUBLIC_URL + "/Images/Icons/" + "copy clipboard.png"}/>
            </div>
        </div>
    );
}