import { createSlice } from  '@reduxjs/toolkit';

export const actionSlice = createSlice({
    name: "action",
    initialState: {
        password: "",
        username: "",
        token: "",
        refreshToken: "",
    },
    reducers: {
        setPassword: (state, action) => {
            state.password = action.payload;
            console.log("in slice pw: ", action.payload, state.password);
        },

        setUsername: (state, action) => {
            state.username = action.payload;
            console.log("in slice username", action.payload, state.username);
        },
        
        setToken: (state, action) => {
            state.token = action.payload;
            console.log("in slice token", action.payload, state.token);
        },
    }
});

export const { setPassword, setUsername, setToken } = actionSlice.actions;
export default actionSlice.reducer;