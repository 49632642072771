import LeftSide from "../Global/components/leftSide";
import { AlreadyHaveAnAccount, ImageRight, MiniLogo, PageTitle } from "../Global/components/reusable";
import {ContinueBtn} from '../Global/components/buttons';
import "./phoneConfirmed.css"
import  { useHistory } from 'react-router-dom';

export default function PhoneConfirmedPage() {
    return(
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide/>
        </div>
    );
}

function RightSide(){
    const history = useHistory();
    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row">
        <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
            
            <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                <div>
                    <MiniLogo/>
                </div>
                
                    <div className="page-title-container">
                        <PageTitle
                            title="Phone verification"
                            message="You're all set!"/>
                    </div>
                </div>

                <div className="phone-confermed-container mx-auto">
                    <div className="checkmark mb-2">
                        <div className="oval"/>
                        <div className="line1"/>
                        <div className="line2"/>
                    </div>

                    <span className="txt">Phone Verified!</span>
                </div>

                <ContinueBtn onClick={() => history.push("/termsconditions")}/>
            
                <div className="already-container-signupPage w-100">
                    <AlreadyHaveAnAccount
                        text="Already have an account?"
                        textGold=" Sign In"/>
                </div>
            </div>
        </div>
    );
}