

import axios from 'axios';
import {useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { API, SessionVars, TokenData, TokenValidation } from '../Global/components/utils';
import './cardDetails.css';

export default function CardDetails({openModal, deliveryAddress, setDeliveryAddress = () => {}}){
    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const [streetAddress, setStreetAddress] = useState("");
    const [streetNumberm, setStreetNumberm] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [postcode, setPostcode] = useState("");
    const [state, setState] = useState("");
    const [error, setError] = useState("");
    const [pin, setPin] = useState("");
    const [modalPosition, setModalPosition] = useState("fixed");

    const history = useHistory();

    const refModal = useRef();

    function HandleResize(){
        if(refModal.current.clientHeight + 20 > window.innerHeight){
            setModalPosition("absolute");
        }
        else{
            setModalPosition("fixed");    
        }
    }

    useEffect(() => {
        HandleResize();
        window.addEventListener("resize", HandleResize);
        return () => window.removeEventListener("resize", HandleResize);
    })

    useEffect(async () => {
        if(deliveryAddress === null){
            if(await TokenValidation()){
                axios.get(API.me, {
                    headers:{
                        Authorization : `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                })
                .then(res =>{
                    let aux = {
                        city: res.data.city,
                        country: res.data.country,
                        addressStreet: res.data.addressStreet,
                        addressNumber: res.data.addressNumber,
                        postcode: res.data.postcode,
                        stateOrProvince: res.data.stateOrProvince,
                        additionalInfo: res.data.addressRefinement
                    }

                    setCity(res.data.city);
                    setCountry(res.data.country);
                    setStreetAddress(res.data.addressStreet);
                    setStreetNumberm(res.data.addressNumber);
                    setPostcode(res.data.postcode);
                    setState(res.data.stateOrProvince)
                    setAdditionalInfo(res.data.addressRefinement)

                    setDeliveryAddress(aux);
                })
                .catch(err => {
                    console.log("ERROR me\n", err.response);
                })
            }
        }
        else{
            setCity(deliveryAddress.city);
            setCountry(deliveryAddress.country);
            setStreetAddress(deliveryAddress.addressStreet);
            setStreetNumberm(deliveryAddress.addressNumber);
            setPostcode(deliveryAddress.postcode);
            setState(deliveryAddress.stateOrProvince)
            setAdditionalInfo(deliveryAddress.additionalInfo)
        }
    }, [])

    useEffect(() => {
        if(error.length > 0){
            setTimeout(() => {
                setError("");
            }, 5000)
        }
    }, [error])

    async function ConfirmAction(){
        if(await TokenValidation()){
            var SHA256 = require("js-sha256").sha256;
            let encryptedPin = SHA256(pin);
            axios.post(API.requestCard, 
            {
                "deliveryMethod": "dhl",
                "design": "",
                "deliveryAddress": {
                    "city": city,
                    "country": country,
                    "number": streetNumberm,
                    "postCode": postcode,
                    "refinement": additionalInfo,
                    "stateOrProvince": state,
                    "street": streetAddress
                },
                "deliveryName": ""
            },
            {
                params:{
                    userPin: encryptedPin
                },
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                } 
            })
            .then(res => {
                console.log(res);
                openModal("Card Done");
            })
            .catch(err => {
                console.log("ERROR ordering a card\n", err.response);

                if(err.response.status === 403){
                    setError("Invalid pin");
                } 
                else{
                    setError("Something failed when proccessing your request");
                }
            })
        }
        else{
            history.replace("/pinCheck");
        }

        setConfirmationPopup(false);
    }

    return(
        <div>
            <div ref={refModal} style={{position: modalPosition}} className="modal-container-card-details d-flex flex-column">   
                <button 
                    onClick={openModal.bind(this, "")}
                    className="close-btn">&times;</button>
                
                    <div className="title-container">
                        <span className="title">Order a card</span>
                    </div>
                    <div className="header">
                        <div className="datails-container">
                            <div className="delivery-address">
                                <span>{streetAddress} no.{streetNumberm}</span>
                                <span>{additionalInfo}</span>
                                <span>{postcode}</span>
                                <span>{city}</span>
                                <span>{state}, {country}</span>
                            </div>

                            <div 
                                onClick={openModal.bind(this, "Card New Address")} 
                                className="confirm-btn">
                                Change delivery address
                            </div>
                        </div>

                        <div className="card-container">
                            <img 
                                src={process.env.PUBLIC_URL + "/Images/wolves-card.jpg"}
                                className="card-logo"/>
                        </div>
                    </div>
                <span className="error-message align-self-center">{error}</span>
                <div className="body">
                    <div
                        onClick= {() => {setConfirmationPopup(true)}}
                        className="continue-btn-cont">
                        <button className="continue-btn">
                            Order Card
                        </button>
                    </div>
                </div>
            </div>
            <div className="overlay"/>
            {confirmationPopup &&(
                <div>
                    <div className="overlay2"/>
                    <div className="verify-popup">
                        <div className="title">
                            Please insert your pin to order your wolves card for only{sessionStorage.getIteme(SessionVars.cardPrice)}£
                        </div>
                        <input onChange={(e) => setPin(e.target.value)} type="password"/>
                        <div>
                            <div onClick={() => ConfirmAction()} className="confirm">
                                <span>Confirm</span>
                            </div>  
                            <div onClick={() => setConfirmationPopup(false)} className="deny">
                                <span>Close</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}