import { useEffect, useState } from "react";
import { BackAndContinueBtns, LargeInputWithTextOnTop, SmallDoubleInputsWithTextOnTop } from "../Global/components/buttons";
import { Dropdown } from "../Global/components/dropdown";
import LeftSide from "../Global/components/leftSide";
import { AlreadyHaveAnAccount, PageTitle, MiniLogo } from "../Global/components/reusable";
import './formRegion.css';
import axios from "axios";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
import { SessionVars } from "../Global/components/utils";

export default function FormRegionPage(){
    return(
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide/>
        </div>
    );
}

function RightSide(){
    const [countyList, setCountyList] = useState([]);
    const [city, setCity] = useState("");
    const [county, setCounty] = useState("");
    const [error, setError] = useState(""); 

    function Sort(arr){
        let i = 0;
        let j = 0;
        let aux;

        while(i < arr.length){
            j = i + 1;
            while(j < arr.length){
                if(arr[i] > arr[j]){
                    aux = arr[i];
                    arr[i] = arr[j];
                    arr[j] = aux;
                }
                j++;
            }
            i++;
        }
        return arr;
    }

    useEffect(() =>{
        async function GetCounties(){
            await axios.get("https://api.banking.bss-lab.it/api/world/lookup-state-by-country-id/232")
                .then(res => {
                    let auxArr = []
                    res.data.map(auxCounty => auxArr.push(auxCounty.name));
                    setCountyList(Sort(auxArr));
                })    
                .catch(err => alert("Some error occured while loading the counties"));
        }
        GetCounties();
    }, []);

    function continueHandler(){
        if(error === ""){
            sessionStorage.setItem(SessionVars.city, city);
            sessionStorage.setItem(SessionVars.stateOrProvince, county);
            window.open("/formLocation", "_self");
        }
    }

    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row" style={{minHeight: '630px'}}>
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>
                
                    <div className="page-title-container">
                        <PageTitle
                            title="Sign Up"
                            message="Let's get started!"/>
                    </div>
                </div>

                <div className="d-flex flex-column align-items-center">
                    <div className="province-container">
                        <Dropdown
                            upText="County"
                            header="Please select your county"
                            setSelect = {setCounty.bind(this)}
                            options={countyList}/>
                    </div>
                    
                    <div className="city-container">
                        <LargeInputWithTextOnTop
                            upText="City"
                            placeholder="Please select your city"
                            error={error}
                            setError={setError.bind(this)}
                            onChange={e => {setCity(e.target.value);}}/>
                    </div>
                </div>
            
                    <BackAndContinueBtns
                        isDisabled = {city === "" || county === ""}
                        continueHandler = {() => continueHandler()}
                        backHandler = {() => window.open("/formName", "_self")}/>

                <div className="already-container-signupPage w-100">
                    <AlreadyHaveAnAccount
                        text="Already have an account?"
                        textGold=" Sign In"/>
                </div>
            </div>
        </div>
    );
}