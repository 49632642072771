import { MenuItemColumn, MenuItemRow } from '../Global/components/buttons';
import { ErrorWithTimer, LoadingScreen, Notification, Popup, ToastPopup, Transaction } from '../Global/components/reusable.js'
import { SecurityPage } from './security';
import AccountPage from './account';
import { createRef, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import React from 'react';
import './dashboar.css';
import FaqPage from './faq';
import TermsAndCondPage from './termsAndCond';
import SupportController from './support';
import { DropdownDateSelector, DropdownWithSimpleArrow } from '../Global/components/dropdown';
import Scrollbars from 'react-custom-scrollbars';
import CardPage from '../Card/card';
import { API, BankAccount, FormatCurrency, GetTransaction, inTransaction, ReversTransactionOrder, SessionVars, TokenData, TokenValidation, topup } from '../Global/components/utils';
import axios from 'axios';
import { monthsName } from '../Global/components/utils';
import WolvesChart from '../Global/components/wolvesChart';

export function DashboarPage(){
    const [currentPage, setCurrentPage] = useState("");

    return(
        <div className="page-container">
            <Main props={{pageToRender: currentPage}}/>
        </div>
    );
}

function Main({props}){
    let component = null;

    switch(props.pageToRender){
        case "Security": {
            component = <SecurityPage/>
            break;}



        case "FAQ": {
            component = <FaqPage/>
            break;
        }

        case "Terms & Conditions": {
            component = <TermsAndCondPage name="Terms & Conditions"/>
            break;
        }

        case "Privacy": {
            component = <TermsAndCondPage name="Privacy"/>
            break;
        }

        case "Support":{
            component = <SupportController/>
            break;
        }

        case "Account":{
            component = <AccountPage/>
            break;
        }

        case "Card":{
            component = <CardPage/>
        }

        default: component = <Home/>;
    }

    return(
        <div className="main-container">
            {component}
        </div>
    );
}

const date = new Date();

export function Home({props}){
    const [transactionType, setTransactionType] = useState("In");
    const [selectedTime, setSelectedTime] = useState("Day");
    const [selectedFilter, setSelectedFilter] = useState("Show All");
    const [barWidth, setBarWidth] = useState(500);
    const [historyAmount, setHistoryAmount] = useState(0);
    const [leftDatepicker, setLeftDatepicker] = useState('unset');
    const [rightDatepicker, setRightDatepicker] = useState('unset');
    const [labels, setLabels] = useState([]);
    const [dataSecondary, setDataSecondary] = useState([]);
    const [dataTotal, setDataTotal] = useState([]);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [deleteAllPopup, setDeleteAllPopup] = useState(false);
    const [range, setRange] = useState([
        {
            selectedDay: date.getDate() - 2,
			selectedMonth: monthsName[date.getMonth()],
			selectedYear: date.getFullYear()
		},
		{
            selectedDay: date.getDate(),
			selectedMonth: monthsName[date.getMonth()],
			selectedYear: date.getFullYear()
		}
	]);
    const [rangeHistory, setRangeHistory] = useState([
        {
            selectedDay: date.getDate() - 2,
			selectedMonth: monthsName[date.getMonth()],
			selectedYear: date.getFullYear()
		},
		{
            selectedDay: date.getDate(),
			selectedMonth: monthsName[date.getMonth()],
			selectedYear: date.getFullYear()
		}
	]);
    const [rangeAnalytics, setRangeAnalytics] = useState([
        {
            selectedDay: date.getDate() - 2,
			selectedMonth: monthsName[date.getMonth()],
			selectedYear: date.getFullYear()
		},
		{
            selectedDay: date.getDate(),
			selectedMonth: monthsName[date.getMonth()],
			selectedYear: date.getFullYear()
		}
	]);
    
    const [notificationList, setNotificationList] = useState([]);
    const [transactionHistoryToDisplay ,setTransactionHistoryToDisplay] = useState([]);
    const [transactionHistory, setTransactionHistory]=  useState([]);
    const [transaction, setTransaction] = useState([]);
    const [trackStyleNotifications, setTrackStyleNotifications] = useState("track-vertical-tc");
    const [trackStyleTransactions, setTrackStyleTransactions] = useState("track-vertical-tc");
    const [transactionError, setTransactionError] = useState("");
    const [notificationsError, setNotificationsError] = useState("");
    const [notificationsErrorTimer, setNotificationsErrorTimer] = useState("");
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [loadingNotifications, setLoadingNotifications] = useState(false);
    const [maxIntervalPopup, setMaxIntervalPopup] = useState(false);
    const history = useHistory();

    const timeOptions = [
        {value: 0, label: 'Day'},
        {value: 1, label: 'Month'},
        {value: 2, label: 'Year'},
    ];
    const filterOptions = ["Show All", "Read", "Unread", "Mark All As Read", "Delete All"];

    const refTimeFilter = useRef();
    const refTypeFilter = useRef();
    const scrollbarNotifications = useRef();
    const contentNotifications = useRef();
    const scrollbarTransactions = useRef();
    const contentTransactions = useRef();
    
    const noDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    var dataArray = new Array();

    const maxRange = [{
        selectedDay: date.getDate(),
        selectedMonth: monthsName[date.getMonth()],
        selectedYear: date.getFullYear() - 2
    },
    {
        selectedDay: date.getDate(),
        selectedMonth: monthsName[date.getMonth()],
        selectedYear: date.getFullYear()
	}];

    const data = {
        labels: labels,
        datasets: [
          {
            data: dataSecondary,
            label: transactionType,
            backgroundColor: `#FDB913`,
            borderRadius: 2,
          },
    
          {
            data: dataTotal,
            label: 'Total',
            backgroundColor: `#FEF4EA`,
            borderRadius: 2,
          },
        ],
    };
    
    const options ={
        barThickness: 25,
        maintainAspectRatio: false,
        
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            },
        },
    
        plugins: {
            legend: {
                display: false
            },
    
            tooltip: {
                callbacks: {
                    label: function(context) {
                        var label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        // if (context.parsed.y !== null) {
                        //     label += new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'EUR' }).format(context.parsed.y);
                        // }
                        return label + FormatCurrency(context.parsed.y);
                    }
                }
            }
        },
    }

    async function deleteAll(){
        setLoadingNotifications(true);
        if(await TokenValidation() === true){
            axios.post(API.deleteAllNotificationsUrl, {}, {
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                props.setNoNotifications(0);
                setLoadingNotifications(false);
            })
            .catch(err => {
                console.log("Error deleting ALL notifcations\n", err);
                setLoadingNotifications(false);
            })
            setSelectedFilter("Show All");
        }
        else{
            history.replace("/pinCheck");
        }
    }

    async function removeFromList(id){
        setLoadingNotifications(true);
        if(await TokenValidation() === true){
            axios.post(API.deleteNotificationUrl, {}, {
                params: {
                    'id' : id
                },
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                let auxList = notificationList.filter(item => item.id !== id);
                setNotificationList(auxList);
                setLoadingNotifications(false);
            })
            .catch(err => {
                console.log("ERROR deleting a notification\n", err);
                setNotificationsErrorTimer("Error deleting the notification");
                setLoadingNotifications(false);
            })
        }
        else{
           history.replace("/pinCheck");
        }
    }

    async function readNotification(id){
        setLoadingNotifications(true);
        if(await TokenValidation() === true){
            axios.post(API.markNotificationAsReadUrl, {}, {
                params: {
                    'id' : id
                },
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                let auxList = [];
                notificationList.forEach(notification => {
                    if(notification.id === id){
                        notification.isRead = true;
                    }
                    auxList.push(notification);
                });
                setNotificationList(auxList);
                setLoadingNotifications(false);
                props.setNoNotifications(props.noNotifications);
            })
            .catch(err => {
                console.log("ERROR reading a notification\n", err);
                setLoadingNotifications(false);
                setNotificationsErrorTimer("Error reading the notification");
            })
        }
        else{
           history.replace("/pinCheck");
        }
    }

    function HandleResize(){
        if(window.innerWidth < 515){
            setLeftDatepicker("-25px");
            setRightDatepicker("unset");
        }
        else{
            setLeftDatepicker("unset");
            setRightDatepicker("0px");
        }
    }
    
    function toggleBorder(props){
        setTransactionType(props.currentTarget.innerHTML);
    }

    function FilterTransactionHistory(value){
        let auxList = [];
        let auxSum = 0;

        transactionHistory.forEach(elem => {
            if(value.toLowerCase() === "in"){
                if(inTransaction.indexOf(elem.type) >= 0){
                    auxList.push(elem);
                    auxSum += parseFloat(elem.amount);
                }
            }
            else if(value.toLowerCase() === "out"){
                if(inTransaction.indexOf(elem.type) < 0){
                    auxList.push(elem);
                    auxSum -= parseFloat(elem.amount);
                }
            }
            else if ("iban transfer".includes(value.toLowerCase()) && "iban transfer".length >= value.length){
                if(elem.merchantCategoryCode == null){
                    if(inTransaction.indexOf(elem.type) < 0){
                        auxList.push(elem);
                        auxSum -= parseFloat(elem.amount);
                    }
                    else{
                        auxList.push(elem);
                        auxSum += parseFloat(elem.amount);
                    }
                }
            }
            else if(elem.createdAt.split("T")[0].includes(value) || elem.amount === parseFloat(value)){
                auxList.push(elem);
                if(elem.status != "Declined"){
                    inTransaction.indexOf(elem.type) < 0 ? 
                    auxSum -= parseFloat(elem.amount): auxSum += parseFloat(elem.amount)
                }
            }
            else if(elem.merchantDetails !== null && elem.merchantDetails !== undefined){
                if(elem.merchantDetails.toLowerCase().includes(value)){
                    auxList.push(elem);

                    if(elem.status != "Declined"){
                        inTransaction.indexOf(elem.type) < 0 ? 
                        auxSum -= parseFloat(elem.amount): auxSum += parseFloat(elem.amount)
                    }
                }
            }
        })

        setTransactionHistoryToDisplay(auxList);
        setHistoryAmount(FormatCurrency(auxSum.toString(), sessionStorage.getItem(BankAccount.currency)));
    }

    //INITIALIZE DATES FOR DATEPICKERS
    useEffect(() => {
        const today = new Date();
        const twoDaysAgo = new Date();

        twoDaysAgo.setDate(today.getDate() - 2);
        const pastDay = twoDaysAgo.getDate();
        const pastMonth = monthsName[twoDaysAgo.getMonth()];
        const pastYear = twoDaysAgo.getFullYear();

        setRange([
            {
                selectedDay: pastDay,
                selectedMonth: pastMonth,
                selectedYear: pastYear
            },
            {
                selectedDay: today.getDate(),
                selectedMonth: monthsName[today.getMonth()],
                selectedYear: today.getFullYear()
            }
        ]);

        setRangeAnalytics([
            {
                selectedDay: pastDay,
                selectedMonth: pastMonth,
                selectedYear: pastYear
            },
            {
                selectedDay: today.getDate(),
                selectedMonth: monthsName[today.getMonth()],
                selectedYear: today.getFullYear()
            }
        ]);

        setRangeHistory([
            {
                selectedDay: pastDay,
                selectedMonth: pastMonth,
                selectedYear: pastYear
            },
            {
                selectedDay: today.getDate(),
                selectedMonth: monthsName[today.getMonth()],
                selectedYear: today.getFullYear()
            }
        ]);

    }, [])

    //EVENT HANDLERS 
    useEffect(() => {
        HandleResize();
        window.addEventListener("resize", HandleResize);
        return () => window.removeEventListener("resize", HandleResize);
    })

    //DISPLAY NOTIFICATION
    useEffect(async () => {
        const token = sessionStorage.getItem(TokenData.token);
        switch (selectedFilter){
            case "Show All":
                setLoadingNotifications(true);
                if(await TokenValidation()){
                    axios.get(API.allNotificationsUrl, {
                        params: {
                            UserId: sessionStorage.getItem(SessionVars.userId),
                            Hidden: false
                        },
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(res => {
                        let auxList = [];
                        let newNotifications = 0;
                        res.data.items.forEach(item => {
                            if(item.hidden === false){
                                auxList.push({
                                    id: item.id,
                                    isRead: item.read != null,
                                    title: item.title
                                });
                            }
                            if(item.isRead === false){
                                newNotifications++;
                            }
                        })
                        props.setNoNotifications(newNotifications);
                        setNotificationList(auxList);
                        setLoadingNotifications(false);
                    })
                    .catch(err => {
                        console.log("ERROR getting all the notifications", err.response);
                        setNotificationsError("Error getting the list of notifications, please try to refresh");
                        setLoadingNotifications(false);
                    })
                }
                else{
                    history.replace("/pinCheck");
                }
                break;
            
            case "Read":{
                setLoadingNotifications(true);
                if(await TokenValidation()){
                    axios.get(API.allNotificationsUrl, {
                        params: {
                            UserId: sessionStorage.getItem(SessionVars.userId),
                            Hidden: false
                        },
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(res => {
                        let auxList = [];
                        res.data.items.forEach(item => {
                            if(item.hidden === false && item.read != null){
                                auxList.push({
                                    id: item.id,
                                    isRead: true,
                                    title: item.title
                                });
                            }
                        })
                        setNotificationList(auxList);
                        setLoadingNotifications(false);
                    })
                    .catch(err => {
                        console.log("ERROR getting the read notifications\n", err.response);
                        setNotificationsError("Error getting the list of notifications, please try to refresh");
                        setLoadingNotifications(false);
                    })
                }
                else{
                    history.replace("/pinCheck");
                }
                break;
            }

            case "Unread":{
                setLoadingNotifications(true);
                if(await TokenValidation()){
                    axios.get(API.allNotificationsUrl, {
                        params: {
                            UserId: sessionStorage.getItem(SessionVars.userId),
                            Hidden: false
                        },
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(res => {
                        let auxList = [];
                        res.data.items.forEach(item => {
                            if(item.hidden === false && item.read === null){
                                auxList.push({
                                    id: item.id,
                                    isRead: false,
                                    title: item.title
                                });
                            }
                        })
                        setNotificationList(auxList);
                        setLoadingNotifications(false);
                    })
                    .catch(err => {
                        console.log("ERROR getting the unread notifications\n", err);
                        setNotificationsError("Error getting the list of notifications, please try to refresh");
                        setLoadingNotifications(false);
                    })
                }
                else{
                    history.replace("/pinCheck");
                }
                break;
            }

            case "Mark All As Read":{
                setLoadingNotifications(true);
                if(await TokenValidation()){
                    axios.post(API.markNotificationsAsReadUrl, {}, {
                        headers:{
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(res => {
                        let auxList = [];
                        notificationList.forEach(notification => {
                            let aux = notification;
                            aux.isRead = true;
                            auxList.push(aux);
                        })
                        setNotificationList(auxList);
                        props.setNoNotifications(0);
                        setLoadingNotifications(false);
                    })
                    .catch(err => {
                        console.log("Error reading ALL notifcations\n", err);
                        setLoadingNotifications(false);
                    })
                    setSelectedFilter("Unread");
                }
                else{
                    history.replace("/pinCheck");
                }
                break;
            }

            case "Delete All":{
                setDeleteAllPopup(true);
                break;
            }
        }
    }, [selectedFilter])
    
    useEffect(() => {
        if(range.length === 2){
            let auxDate = {
                day: parseInt(range[0].selectedDay),
                month: monthsName.indexOf(range[0].selectedMonth),
                year: parseInt(range[0].selectedYear)
            }
            
            switch(selectedTime){
                case "Day":{
                    while((auxDate.day != range[1].selectedDay || auxDate.month != monthsName.indexOf(range[1].selectedMonth) || auxDate.year != range[1].selectedYear)){
                        dataArray.push(auxDate.day.toString() + " " + monthsName[auxDate.month].substring(0, 3));
                        auxDate.day += 1
                        
                        if(dataArray.length === 365){
                            alert("You can view a maxim of 366 days");
                            break;
                        }

                        if(auxDate.year % 4 === 0)
                            noDaysInMonth[1] = 29;
                        else
                            noDaysInMonth[1] = 28;
        
                        if(auxDate.day > noDaysInMonth[auxDate.month]){
                            auxDate.day = 1;
                            auxDate.month += 1;
                            
                            if(auxDate.month == 12){
                                auxDate.month = 0;
                                auxDate.year += 1;
                            }
                        }
                    }
                    dataArray.push(auxDate.day.toString() + " " + monthsName[auxDate.month].substring(0, 3));
                    break;
                }

                case "Month":{
                    while((auxDate.month != monthsName.indexOf(range[1].selectedMonth) || auxDate.year != range[1].selectedYear)){
                        dataArray.push(monthsName[auxDate.month].substring(0, 3) + " " + auxDate.year);

                        if(dataArray.length === 365){
                            alert("You can view a maxim of 366 months");
                            break;
                        }

                        auxDate.month += 1;
                        if(auxDate.month == 12){
                            auxDate.month = 0;
                            auxDate.year += 1;
                        }
                    
                    }
                    dataArray.push(monthsName[auxDate.month].substring(0, 3) + " " + auxDate.year);
                    break;
                }

                case "Year":{
                    while((auxDate.year != range[1].selectedYear)){
                        dataArray.push(auxDate.year);

                        if(dataArray.length === 365){
                            //  alert("You can view a maxim of 366 years");
                            break;
                        }

                        auxDate.year += 1;
                    }
                    dataArray.push(auxDate.year)
                    break;
                }
            }
        }
    }, [range, selectedTime])

    useEffect(async () => {
        setLoadingTransactions(true);
        if(await TokenValidation()){
            let res = null;
            let auxList = [];
            let offset = 0;
 
            try{
                do{
                    res = await GetTransaction(maxRange, offset);
                    offset += 100
                    if(res.data !== ""){
                        res.data.forEach(el => {
                            if(el.amount != 0);
                                auxList.push(el)
                        })
                    }
                }while(res.data.lenght === 100)
                setLoadingTransactions(false);
            }
            catch(err){
                console.log("ERROR getting the transactions\n", err);
                setTransactionError("Error loading transactions, please try to refresh");
                setLoadingTransactions(false);
            }
            if(auxList.length.toString() != sessionStorage.getItem(BankAccount.totalNoTransactions)){
                sessionStorage.setItem(BankAccount.amount, null);
            }
            sessionStorage.setItem(BankAccount.totalNoTransactions, auxList.length);
            setTransaction(ReversTransactionOrder(auxList));
        }
        else{
            history.replace("/pinCheck");
        }
    }, [])

    useEffect(() => {
        if(rangeHistory.length === 2){
            //setHistoryTimeInterval(rangeHistory[0].selectedMonth + " " + rangeHistory[0].selectedYear + " - " + rangeHistory[1].selectedMonth + " " + rangeHistory[1].selectedYear);
            let auxList = [];
            let auxSum = 0;
            transaction.forEach(elem => {
                const date = elem.createdAt.split("T")[0];
                const year = date.split("-")[0];
                const month = date.split("-")[1];
                const day = date.split("-")[2];

                const elemDate = new Date(year, month - 1, day);
                const minDate = new Date(rangeHistory[0].selectedYear, monthsName.indexOf(rangeHistory[0].selectedMonth), rangeHistory[0].selectedDay);
                const maxDate = new Date(rangeHistory[1].selectedYear, monthsName.indexOf(rangeHistory[1].selectedMonth), rangeHistory[1].selectedDay);

                if(elemDate <= maxDate && elemDate >= minDate){
                    auxList.push(elem);
                    if(elem.status != "Declined"){
                        if(inTransaction.indexOf(elem.type) >= 0){
                            auxSum += elem.amount;
                        }
                        else{
                            auxSum -= elem.amount;
                        }
                    }
                    auxSum = Math.round(auxSum * 100)/100;
                }
            })
            setHistoryAmount(FormatCurrency(auxSum.toString(), sessionStorage.getItem(BankAccount.currency))); 
            setTransactionHistory(auxList);
            setTransactionHistoryToDisplay(auxList);
        }
    }, [rangeHistory, transaction])

    function FormatTransactionType(type){
        if(inTransaction.indexOf(type) >= 0)
            return "In";
        else if (type === "Unknown")
            return "Pending";

        return "Out";
    }

    function HandleResizeNotifications(){
        if(contentNotifications.current !== undefined && scrollbarNotifications.current !== undefined &&
            contentNotifications.current !== null && scrollbarNotifications.current !== null){   
            if(contentNotifications.current.clientHeight > scrollbarNotifications.current.clientHeight){
                setTrackStyleNotifications("track-vertical-tc");
            }
            else{
                setTrackStyleNotifications("track-hidden");
            }
        }
    }

    function HandleResizeTransactions(){
        if(contentTransactions?.current !== undefined && scrollbarTransactions?.current !== undefined){  
            if(contentTransactions.current?.clientHeight > scrollbarTransactions.current?.clientHeight){
                setTrackStyleTransactions("track-vertical-tc");
            }
            else{
                setTrackStyleTransactions("track-hidden");
            }
        }
    }

    useEffect(() => {
        window.addEventListener("resize", HandleResizeNotifications);
        window.addEventListener("resize", HandleResizeTransactions);

        return () => {
            window.removeEventListener("resize", HandleResizeNotifications);
            window.removeEventListener("resize", HandleResizeTransactions);
        }
    }, [])

    useEffect(() => {
        HandleResizeNotifications();
        HandleResizeTransactions();
    }, [transactionHistoryToDisplay, notificationList])

    useEffect(async () => {
        if(range.length === 2){
            let labelsAux = [];
            let dataTotalAux = [];
            let dataSecondaryAux = [];
            
            transaction.filter(elem => elem.status != "Declined").forEach(elem => {
                const date = elem.createdAt.split("T")[0];
                const year = date.split("-")[0];
                const month = date.split("-")[1];
                const day = date.split("-")[2];
                const elemDate = new Date(year, month - 1, day);
                const minDate = new Date(range[0].selectedYear, monthsName.indexOf(range[0].selectedMonth), range[0].selectedDay);
                const maxDate = new Date(range[1].selectedYear, monthsName.indexOf(range[1].selectedMonth), range[1].selectedDay);
                if(selectedTime === "Day"){
                    if(elemDate <= maxDate && elemDate >= minDate){
                        const poss = labelsAux.indexOf(day + " " + monthsName[month - 1].substr(0, 3))
                        if(poss == -1){
                            labelsAux.push(day + " " + monthsName[month - 1].substr(0, 3));
                            dataTotalAux.push(elem.amount);
    
                            // if(transactionType === "Pending"){
                            //     if(elem.status === "Pending")
                            //         dataSecondaryAux.push(elem.amount);
                            //     else
                            //         dataSecondaryAux.push(0);
                            // }
                            // else{
                                if(transactionType === FormatTransactionType(elem.type))
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            //}
                        }
                        else{
                            dataTotalAux[poss] += elem.amount;
                            if(transactionType === FormatTransactionType(elem.type)){
                                dataSecondaryAux[poss] += Math.abs(elem.amount)
                            }
                        }
                        
                    } 
                }
                else if(selectedTime === "Month"){
                    if(elemDate <= maxDate && elemDate >= minDate){
                        const poss = labelsAux.indexOf(monthsName[month - 1].substr(0, 3) + " " + elemDate.getFullYear());
                        if(poss == -1){
                            labelsAux.push(monthsName[month - 1].substr(0, 3) + " " + elemDate.getFullYear());
                            dataTotalAux.push(elem.amount);
    
                            if(transactionType === "Pending"){
                                if(elem.status === "Pending")
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            }
                            else{
                                if(transactionType === FormatTransactionType(elem.type))
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            }
                        }
                        else{
                            dataTotalAux[poss] += elem.amount;
                            if(transactionType === FormatTransactionType(elem.type)){
                                dataSecondaryAux[poss] += Math.abs(elem.amount)
                            }
                        }
                        
                    }
                }
                else{
                    if(elemDate <= maxDate && elemDate >= minDate){
                        const poss = labelsAux.indexOf(elemDate.getFullYear());
                        if(poss == -1){
                            labelsAux.push(elemDate.getFullYear());
                            dataTotalAux.push(elem.amount);
    
                            if(transactionType === "Pending"){
                                if(elem.status === "Pending")
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            }
                            else{
                                if(transactionType === FormatTransactionType(elem.type))
                                    dataSecondaryAux.push(elem.amount);
                                else
                                    dataSecondaryAux.push(0);
                            }
                        }
                        else{
                            dataTotalAux[poss] += elem.amount;
                            if(transactionType === FormatTransactionType(elem.type)){
                                dataSecondaryAux[poss] += Math.abs(elem.amount)
                            }
                        }
                        
                    }
                }
            })
            var chartContainerWidth = document.getElementById("chart-container").clientWidth;
            setLabels(labelsAux);
            setDataSecondary(dataSecondaryAux);
            setDataTotal(dataTotalAux);
            setBarWidth(labelsAux.length * 30 > chartContainerWidth ? labelsAux.length * 30 : chartContainerWidth);
        }
    }, [range, selectedTime, transactionType, transaction])

    let inputStyle = isSearchActive ? "search-input activated" : "search-input deactivated";

    return(
        <div style={{marginTop: '-45px'}} className="home-container">
            {maxIntervalPopup && (
                <Popup 
                    message="The maximum interval for witch you can view your transactions is 2 years"
                    closeAction={() => setMaxIntervalPopup(false)}
                    hasActions={false}/>
            )}
           
            {deleteAllPopup && (
                <Popup  
                    message = "This action will delete all notifications. Are you sure you want to procced?"
                    closeAction = {() => setDeleteAllPopup(false)}
                    denyAction = {() => { setDeleteAllPopup(false); setSelectedFilter("Show All")}}
                    confirmAction={() => {deleteAll(); setDeleteAllPopup(false)}}/>
            )}

            <div className= "grid-container row p-0 m-0">
                <div className="item col-lg-6 col-md-8 col-12">
                    {loadingTransactions && (<LoadingScreen position="absolute"/>)}
                    <div className="inner-container">
                        <div className="top">
                            <span className="text">Transaction Analytics</span>
                            <div className="date-picker-header">
                                <div className="date-picker-container"> 
                                    <DropdownDateSelector
                                        id="home-1"
                                        range={rangeAnalytics} 
                                        left={leftDatepicker}
                                        right={rightDatepicker}
                                        setRange = {setRangeAnalytics.bind(this)}/>
                                </div>
                            </div>
                        </div>  
                        <div className="wolveschart-container">
                            {transactionError.length > 0 ? 
                                <div style={{textAlign: 'center'}} className="error-message w-100">
                                    {transactionError}
                                </div>
                                :
                                <WolvesChart range={rangeAnalytics}/>
                            }
                        </div>
                    </div> 
                </div>

                <div className="item col-lg-6 col-md-8 col-12">
                    {loadingTransactions && (<LoadingScreen position="absolute"/>)}
                    <div className="inner-container">
                        <div className="top">
                            <span className="text">Transactions</span>
                            <div className="date-picker-header">
                                <div className="date-picker-container"> 
                                    <DropdownDateSelector
                                        id="home-2"
                                        range ={range}
                                        left={leftDatepicker}
                                        right={rightDatepicker}
                                        setRange={setRange.bind(this)}/>
                                </div>
                            </div>
                        </div>

                        <div style={{height: '40px'}} className="middle">
                            <div className="transaction-type-container">
                                <div
                                    className={transactionType == "In" ? "selected" : ""} 
                                    onClick={toggleBorder.bind(this)}>In</div>

                                <div
                                    className={transactionType == "Out" ? "selected" : ""} 
                                    onClick={toggleBorder.bind(this)}>Out</div>

                                <div
                                    className={transactionType == "Pending" ? "selected" : ""} 
                                    onClick={toggleBorder.bind(this)}>Pending</div>
                            </div>
            
                            <div className="time-interval-container">
                                <DropdownWithSimpleArrow
                                    headerValue={selectedTime}
                                    refPassed={refTimeFilter}
                                    setSelected={setSelectedTime.bind(this)}
                                    option={["Day", "Month", "Year"]}/>
                            </div>
                        </div>
                        
                        <div id="chart-container" className="chart-container">

                            {transactionError.length > 0 ? 
                                <div style={{width: '100%',height: '100%', display:'flex'}}>
                                    <div style={{margin: 'auto'}}className="error-message">
                                        {transactionError}
                                    </div>
                                </div>
                                :
                                <div style={{width: '100%',height: '100%', display:'flex'}}>
                                {
                                    dataSecondary.length === 0 ? 
                                        <span style={{margin: 'auto', fontFamily:'FSAlbertPro', fontSize: '20px'}}>
                                            No transactions to display for this time interval
                                        </span>
                                    :
                                    <div style={{width:barWidth ,minWidth: '100%', height: 290}}>
                                        <Bar 
                                            data={data}
                                            width={"100%"}
                                            options= {options}/>
                                    </div>
                                }
                                </div>
                            }

                            
                           
                        </div>
                    </div>
                </div>
                
                <div className="item">
                    <div className="inner-container">
                        <div className="top">
                            <span className="text">Notifications</span>
                            <div className="filter-container2">
                                <DropdownWithSimpleArrow
                                    headerValue={selectedFilter}
                                    refPassed={refTypeFilter}
                                    headerFontSize="24px"
                                    setSelected={setSelectedFilter.bind(this)}
                                    option={filterOptions}/>
                            </div>
                        </div>
                            
                        <div
                            ref={scrollbarNotifications} 
                            className="transactions-container-dashboard" 
                            style={{marginTop:"29px", minHeight:"340px"}}>

                        {notificationsErrorTimer && (
                            <ErrorWithTimer 
                                setError={setNotificationsErrorTimer.bind(this)} 
                                err={notificationsErrorTimer}/>
                        )}

                        {
                            notificationsError.length > 0 ? 
                            <div style={{width: '100%', height: '100%', display:'flex'}}>
                                <div style={{margin: 'auto'}} className="error-message">
                                    {notificationsError}
                                </div>
                            </div>
                            :
                            <div style={{width: '100%', height: '100%', display: 'flex'}}>
                            {notificationList.length > 0 && (
                                <Scrollbars
                                    renderView={props => <div {...props} className="view-tc"/>}
                                    renderTrackVertical={props => <div {...props} className={trackStyleNotifications}/>}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical-tc"/>}
                                    style={{height: '100%', width: `100%`, marginLeft: 0}}>
                                    <div ref={contentNotifications}>   
                                        {notificationList.map((notification, index) => {
                                            return(
                                                <Notification
                                                    key={index}
                                                    removeFromList={() => removeFromList(notification.id)}
                                                    readNotification={() => readNotification(notification.id)}
                                                    isRead={notification.isRead}
                                                    text={notification.title}/>
                                            )
                                        })}
                                    </div>
                                </Scrollbars>
                            )}
                                    {notificationList.length === 0 && (
                                        <div style={{margin: 'auto', fontFamily: 'FSAlbertPro', fontSize: '20px'}}>
                                            No notifications to display
                                        </div>
                                    )}
                            </div>
                        }

                        
                        </div>
                    </div>
                </div>
                
                <div className="item">
                    {loadingTransactions && (<LoadingScreen position="absolute"/>)}
                    <div className="inner-container">
                        <div className="top transaction-history">
                            <span className="text">Transactions History</span>
                            <div className="date-picker-header">
                                <div className="date-picker-container"> 
                                    <DropdownDateSelector
                                        id="home-3"
                                        range={rangeHistory}
                                        left={leftDatepicker}
                                        right={rightDatepicker}
                                        setRange={setRangeHistory.bind(this)}/> 
                                </div>
                            </div>
                        </div>
                        
                        <div className="middle">
                            <div className="information-contaier">
                                <span className="money">
                                    {historyAmount}
                                </span>
                            </div>

                            <div className="buttons-container">
                                <div className="search-area">
                                    <input 
                                        onChange={e => FilterTransactionHistory(e.target.value)}
                                        className={inputStyle}
                                        placeholder="search.."/>
                                </div>
                                <div
                                    onClick={() => {setIsSearchActive(!isSearchActive)}} 
                                    className="search">
                                    <img 
                                        width="15"
                                        style={{marginLeft: '13.5px', marginRight: '13.5px'}}
                                        src={process.env.PUBLIC_URL + "Images/Icons/loupe.png"}/>
                                </div>

                                <button onClick = {() => {history.push("/transactions");}}>
                                    <span>View more</span>
                                </button>
                            </div>
                        </div>
                   
                        <div ref={scrollbarTransactions} className="transactions-container-dashboard">

                        {transactionError.length > 0 ? 
                            <div style={{width: '100%', height: '100%', display: 'flex'}}>
                                <div style={{margin: 'auto'}} className="error-message">
                                    {transactionError}
                                </div>
                            </div>
                            :
                            <div style={{height: '100%', width: '100%', display: 'flex'}}>
                                {transactionHistoryToDisplay.length > 0 && (
                                    <Scrollbars
                                        renderView={props => <div {...props} className="view-tc"/>}
                                        renderTrackVertical={props => <div {...props} className={trackStyleTransactions}/>}
                                        renderThumbVertical={props => <div {...props} className="thumb-vertical-tc"/>}
                                        style={{height: `100%`, width: `100%`, marginLeft: 0}}>
                                        <div ref={contentTransactions}>
                                            {transactionHistoryToDisplay.map(elem => (
                                                <Transaction
                                                    status={elem.status}
                                                    person={elem.merchantName}
                                                    date={elem.createdAt}
                                                    amount={elem.amount}
                                                    type={elem.type}/>
                                            ))}
                                        </div>
                                    </Scrollbars>
                                )}
                                {transactionHistoryToDisplay.length === 0 && (
                                    <div style={{margin: 'auto', textAlign:'center', fontFamily: 'FSAlbertPro', fontSize: '20px'}}>
                                        No transactions to display for this time interval
                                    </div>
                                )}
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export class Header extends React.Component{
    constructor(props) {
        super(props);
        this.wrapperRef = createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    
    state = {
        isSearchActive: false,
        isRightMenuOpen: false,
        offsetFromTop: 130
    }

    CheckHeight() {
        if(this.state.isRightMenuOpen){
            if(window.innerHeight >= 885){
                const track = document.getElementById("track");
                if(track !== null)
                    track.style.visibility = "hidden";
            }
            else{
                const track = document.getElementById("track");
                const thumb = document.getElementById("thumb");
                const scrollbar = document.getElementById("scrollbar");
                let ratio;
                let thumbHeight
                if(scrollbar !== null){
                    ratio = scrollbar.clientHeight / 750;
                    thumbHeight =  scrollbar.clientHeight * ratio;
                }
                
                if(thumb !== null)
                    thumb.style.height = `${thumbHeight}px`;

                if(track !== null) 
                    track.style.visibility = "visible";
            }
        }
    }

    componentDidMount() { 
        this.CheckHeight();
        document.addEventListener('mousedown', this.handleClickOutside);
        //CHECK RIGHT MENU SIZE
        window.addEventListener('resize', () =>{
           this.CheckHeight();
        })

        window.addEventListener('scroll',() => {
            this.setState({isRightMenuOpen: false})
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('scroll', this.toggleActive());
    }

    toggleActive = () => { 
        this.setState({isSearchActive: !this.state.isSearchActive});
    };
    
    toggleRightMenu = () => {
        this.setState({offsetFromTop: 130 - window.scrollY});
        this.setState({isRightMenuOpen: !this.state.isRightMenuOpen});
        setTimeout(() =>{
            this.CheckHeight();
        })
    }

    passToggle(){
        this.toggleRightMenu();
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
            this.setState({isRightMenuOpen: false});
        }
    }
    
    outlineStyleProp = () =>{
        if(this.state.isSearchActive)
            return "searchbar-container-outlined searchbar-container"
        else    
            return "searchbar-container";
    } 
    
    render(){
    return(
        <div className="header-container p-0 m-0">
            <div className="dashboar-text">
                <div className="arrow-header"/>
                <span className="text-header">Dashboard</span>
                <div 
                    ref={this.props.props.navBarTextRef}
                    onClick={this.props.props.toggleNavBar}
                    className="menu-icon"/>
            </div>

            <div className="right-half p-0 mx-0">
                <div className={this.outlineStyleProp()}>
                    <input 
                        className="search-bar"
                        onFocus={this.toggleActive}
                        onBlur={this.toggleActive}
                        autoComplete={"off"}
                        placeholder="Search here"/>
                    <div className="icon-container">
                        <img src={process.env.PUBLIC_URL + '/Images/Icons/loupe.png'} 
                            className="search-icon"
                            width="16px"
                            height="16px"/>
                    </div>
                </div>

                <div className="d-flex flex-row">
                <div
                    onClick={() => this.props.props.history.push("/news")} 
                    className="notification-bell-container">
                    <img
                        src= {process.env.PUBLIC_URL + "/Images/Icons/bell.png"}
                        className="notification-bell-icon"/>
                    {this.props.props.noOfNotifications > 0 && (
                        <div className="bubble">
                            <span>{this.props.props.noOfNotifications}</span>
                        </div>
                    )}
                </div> 

                {this.state.isRightMenuOpen && (
                    <div style={{top: `${this.state.offsetFromTop}px `}} className="overlay-2"/>
                )}
                <div 
                    ref={ this.wrapperRef } 
                    className="hello-user-container">

                    <span>{this.props.props.username != null ? this.props.props.username : "Hi, logged user"}</span>
                        <img 
                            src={this.props.props.profilePic}
                            width="70px"
                            height="70px"
                            className="profile-pic"
                            onClick={this.toggleRightMenu}/>

                {this.state.isRightMenuOpen &&(
                    <div id="rightMenuHide" className="hide">
                        <Scrollbars 
                            id="scrollbar"
                            renderView={props => <div {...props} className="view-tc-rightMenu"/>}
                            renderTrackVertical={props => <div id="track" {...props} className="track-vertical-tc-rightMenu"/>}
                            renderThumbVertical={props => <div id="thumb" {...props} className="thumb-vertical-tc-rightMenu"/>}
                            universal={true}
                            style={{width: '100%', height: '100%', marginRight: '0px', marginBottom: 0}}>
                            <RightMenu
                                id="rightMenu"
                                props={{isRightMenuOpen:this.state.isRightMenuOpen,
                                    toggleRightMenu:this.toggleRightMenu.bind(this),
                                    setCurrentPage:this.props.props.setCurrentPage.bind(this)}}
                                    style={{marginTop: "50px"}}/>
                        </Scrollbars>
                    </div>
                )}
                </div>
                </div>
            </div> 
        </div>
    );
    }
}

export function RightMenu({props}){
    const history = useHistory();

    return(
        <div className="right-menu-continar"> 
            <div className="items-container">
                <MenuItemRow
                    onClickFunction = {() => history.push("/dashboard/security")}
                    icon="Security.png"
                    width="44"
                    height="35"
                    text="Security"/>

                <MenuItemRow
                    onClickFunction = {() => history.push("/dashboard/personalInfo")}
                    icon="PersonalInfo.png"
                    width="49"
                    height="35"
                    text="Personal Information"/>  
            
                <MenuItemRow
                    onClickFunction = {() => history.push("/dashboard/faq")}
                    icon="FAQ.png"
                    width="42"
                    height="38"
                    text="FAQ"/>
            
                <MenuItemRow
                    onClickFunction = {() => history.push("/dashboard/termsAndCond")}
                    icon="TermsCond.png"
                    width="31"
                    height="38"
                    text="Terms & Conditions"/>

                <MenuItemRow
                    onClickFunction = {() => history.push("/dashboard/privacy")}
                    icon="Privacy.png"
                    text="Privacy"/>
                
                <MenuItemRow
                    onClickFunction = {() => history.push("/dashboard/support")}
                    icon="Support.png"
                    width="44"
                    height="35"
                    text="Support"/>
        
                <MenuItemRow
                    onClickFunction = {() => history.push("/dashboard/account")}
                    icon="Account.png"
                    width="47"
                    height="32"
                    text="Account"/>

                <MenuItemRow
                    onClickFunction = {() => history.push("/dashboard/bankInfo")}
                    icon="Bank_Information_Icon.png"
                    width="47"
                    height="32"
                    text="Bank information"/>

            </div>
        </div>
    );
}

export function NavigationBar({props}){
    const navBarRef = useRef(null);
    const history = useHistory();

    function useOutsideAlerter(ref) {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if ((props.navBarTextRef.current || ref.current) && !props.navBarTextRef.current.contains(event.target) &&
                    !ref.current.contains(event.target)) 
                {
					props.closeNavBar();
				}
			}

			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

    function IsActive(name){
        return history.location.pathname.includes(name);
    }

    useOutsideAlerter(navBarRef);

    return(
        <div ref={navBarRef} className="navigation-container">
            <div className="logo-container">
                <img
                    src={process.env.PUBLIC_URL + "/Images/Heads/Wolves Badge CMYK Copy 2.png"} 
                    width="52"
                    height="52"
                    className="logo"/> 
                <img 
                    src={process.env.PUBLIC_URL + "/Images/Icons/Back\ arrow.png"}
                    width="28"
                    height="28" 
                    className="arrow-navigation" 
                    style={{cursor: "pointer"}}    
                    onClick={props.closeNavBar}/>
            </div>

            <div className="items-container">
                <div className="navigation">

                    <MenuItemColumn 
                        onClickFunction = {() => history.push("/home")}
                        icon="Home.png"
                        height="20"
                        width="18"
                        isActive = {IsActive("home")}
                        text="Home"/>

                    <MenuItemColumn 
                        onClickFunction = {() => history.push("/cardList")}
                        icon="Card.png"
                        width="26"
                        height="17"
                        isActive = {IsActive("card")}
                        text="Card"/>

                    <MenuItemColumn 
                        onClickFunction = {() => history.push("/topup")}
                        icon="Topup.png"
                        width="26"
                        height="21"
                        isActive = {IsActive("topup")}
                        text="Top-up"/>

                    <MenuItemColumn 
                        onClickFunction = {() => history.push("/transactions")}
                        icon="Transaction.png"
                        width="26"
                        height="19"
                        isActive = {IsActive("transactions")}
                        text="Transactions"/>
                </div>

                <button onClick={() => {props.setLogoutPopup(true);}} className="btn-logout">Log out</button>
            </div>
        </div>
    );
}
