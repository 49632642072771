import './main.css';
import CardSecurity from './cardSecurity';
import CardSettings from './cardSettings';
import OrderCard from './orderCard';
import CardDetails from './cardDetails';
import CardNewAddress from './cardNewAddress';
import CardConfirm from './cardConfirm';
import CardDone from './cardDone';
import OrderCardHome from './orderCardHome';
import { useState } from 'react';

export default function CardHome({openModal = () => {}, cardInfo = {}, modal = ""}){
    return(
        <div className="">
            <OrderCardHome cardInfo={cardInfo} modal={modal} openModalUp={openModal.bind(this)}/>
        </div>
    );
}
