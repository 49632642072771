import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../css/StickyHeader.css';

export function StickyHeader(){
    const [displayName, setDisplayName] = useState(window.innerWidth > 950);

    const history = useHistory();

    function HandleResize(){
        setDisplayName(window.innerWidth > 950);
    }

    useEffect(() => {
        window.addEventListener("resize", HandleResize);
        return () => window.removeEventListener("resize", HandleResize);
    }, [])

    return(
        <div className="sticky-header-container">
            <div className="d-flex">
                <div
                    onClick={() => history.push("/app")} 
                    className="header-button">
                    <span>
                        Create Account
                    </span>
                </div>

                <div onClick={() => history.push("/pricing")} className="link">
                    <span>
                        Pricing
                    </span>
                </div>
            </div>
            <div onClick={() => history.push("/")} className="header-name d-flex align-item-center">
                {displayName && (
                    <div className="name">
                        <span>
                            Wolves&nbsp;
                        </span>
                        <span style={{fontFamily: 'FSAlbertPro'}}>
                            Official Debit Card
                        </span>
                    </div>
                )}
                <div className="logo">
                    <img height="70px" src={process.env.PUBLIC_URL + "./Images/Heads/Wolves Badge CMYK Copy 2.png"}/>
                </div>
            </div>
        </div>        
    );
}

export function DownloadContainer(){
    return(
        <div className="download-container">
            <div className="title-download">
                Download the App
            </div>
            <div className="icons-container">
                <img src={process.env.PUBLIC_URL + "/Images/Icons/app-store.png"} alt="img"/>
                <img src={process.env.PUBLIC_URL + "/Images/Icons/google-play.png"} alt="img"/>
            </div>
        </div>
    );
}