import { ContinueBtn } from "../Global/components/buttons";
import { MiniLogo, PageTitle } from "../Global/components/reusable";
import SlideShow from "../Global/components/slideshow";
import { useHistory } from 'react-router-dom';
import './signDone.css';

export default function SignDonePage(props){
    console.log(props);
    return(
        <div className="container-fluid row main m-0 p-0">
            <div 
                className="slideshow-container-main d-flex justify-content-center col-md-5 p-0 m-0">
                <SlideShow/>
            </div>
            <RightSide message={props.location.state.message}/>
        </div>
    );
}

function RightSide({message = "Your information is being proccessed"}){
    
    const history = useHistory();
    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row">
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>

                    <div className="page-title-container">
                        <PageTitle
                            title="Authorisation proccess"
                            message="Please allow up to 48h for this to be completed."/>
                    </div>
                </div>

                <div className="d-flex flex-column w-100 align-items-center">
                    <div className="bell"/>
                    <span className="verifing-info-text">{message}</span>
                </div>
                <div className="back-btn-container2">
                    <ContinueBtn
                        onClick= {() => {history.replace("/app")}}
                        message="Done"/>
                </div>
            </div>
        </div>
    );
}