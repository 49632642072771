import LeftSide from '../Global/components/leftSide'
import {AlreadyHaveAnAccount, LoadingScreen, MiniLogo, PageTitle} from '../Global/components/reusable'
import { BackAndContinueBtns, LargeInputWithTextOnTop } from '../Global/components/buttons';
import './formPassword.css'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API, SessionVars, TokenData } from '../Global/components/utils';
import axios from 'axios';
import { min } from 'moment';
    

export default function FormPasswordPage(){
    return(
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide/>
        </div>
    );
}

function RightSide(){
    const [passwordLocal, setPasswordLocal] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [pwError, setPwError] = useState("");
    const [confError, setConfError] = useState("");
    const [minWidth, setMinWidth] = useState("225px");
    const [isLoading, setIsLoading] = useState(false);

    function Resize() {
        if(window.innerWidth <= 760){
            setMinWidth("190px");
        }
        else{
            setMinWidth("225px");
        }
    }

    useEffect(() => {
        Resize();
        window.addEventListener("resize", Resize);
        return () => window.removeEventListener("resize", Resize);
    })

    function continueHandler2() {
        if(!/[0-9]+/.test(passwordLocal) || !/[A-Z]+/.test(passwordLocal)){
            setConfError("Your password needs to contain at least one capital letter and one number");
            return;
        }
        
        if(!/[!@#$%^&£*?~`';()[\]]/.test(passwordLocal)){
            setConfError("Your password needs to contain at least one special character(!@#$%^&*?£()[]~`';)");
            return;
        }
        
        if(passwordLocal.length < 6){
            setConfError("Your password needs to be at least 6 characters long")
            return;
        }
        
        if(passwordLocal != confirmPassword){
            setConfError("Your passwords don't match");
            return;
        }  
        
        let dateOfBirthSplit = sessionStorage.getItem(SessionVars.dateOfBirth).split("/");
        let dateOfBithFormated = "";
        
        dateOfBithFormated += dateOfBirthSplit[2] + '-' + dateOfBirthSplit[1] + '-';
        
        if(dateOfBirthSplit[0].length === 1){
            dateOfBithFormated += "0" + dateOfBirthSplit[0];
        }
        else{
            dateOfBithFormated += dateOfBirthSplit[0];
        }
        dateOfBithFormated += "T13:38:14.951Z";
                
        let objRegister = {
            password: passwordLocal,
            appName: sessionStorage.getItem(SessionVars.appName),
            firstName: sessionStorage.getItem(SessionVars.firstName),
            surName: sessionStorage.getItem(SessionVars.surName),
            phoneNumber: sessionStorage.getItem(SessionVars.phoneNumber),
            emailChannelId: sessionStorage.getItem(SessionVars.emailChannelId),
            emailChannelCode: sessionStorage.getItem(SessionVars.emailChannelCode),
            phoneChannelId: sessionStorage.getItem(SessionVars.phoneChannelId),
            phoneChannelCode: sessionStorage.getItem(SessionVars.phoneChannelCode),
            dateOfBirth: dateOfBithFormated,
            addressRefinement: sessionStorage.getItem(SessionVars.addressRefinement),
            city: sessionStorage.getItem(SessionVars.city),
            stateOrProvince: sessionStorage.getItem(SessionVars.stateOrProvince),
            postcode: sessionStorage.getItem(SessionVars.postcode),
            country: "United Kingdom",
            addressStreet: sessionStorage.getItem(SessionVars.addressStreet),
            addressNumber: sessionStorage.getItem(SessionVars.addressNumber),
            profession: sessionStorage.getItem(SessionVars.profession),
            occupation: sessionStorage.getItem(SessionVars.occupation),
            monthlyIncome: sessionStorage.getItem(SessionVars.monthlyIncome),
            membershipNumber: sessionStorage.getItem(SessionVars.membershipNumber),
            hasAgreedTerms: sessionStorage.getItem(SessionVars.hasAgreedTerms) === "true",
            hasAgreedTermsPrivacy: sessionStorage.getItem(SessionVars.hasAgreedTermsPrivacy) === "true",
            hasAgreedMarketing: sessionStorage.getItem(SessionVars.hasAgreedMarketing) === "true",
            returnUrl: null,
            returnUrlHash: null,
            captchaResponse: null,
        }
        setIsLoading(true);

        axios.post(API.registerUrl, objRegister)
        .then(res => {
            console.log("Registered: ", res.data);
            sessionStorage.setItem(SessionVars.username, res.data.userName);
            sessionStorage.setItem(SessionVars.userId, res.data.id);
            document.getElementById("next").click();
            setIsLoading(false);
        })
        .catch(err => {
            console.log("Failed signup: ", err.response);
            if(err.response.data.error.message.includes("already") &&
            err.response.data.error.message.includes("taken") ){
                setConfError("Email is already taken");
            }
            else if(err.response.status >= 400 && err.response.status < 500){
                setConfError("Server might be down right now. Please try a little later");
            }
            else{
                setConfError(err.response.data.error.message);
            }
            setIsLoading(false);
        });        
    }

    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row" style={{minHeight: '540px'}}>
            {isLoading && (<LoadingScreen/>)}
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>
                
                    <div className="page-title-container">
                        <PageTitle
                            title="Sign Up"
                            message="Let's get started!"/>
                    </div>
                </div>

            <div className="d-flex flex-column align-items-center">
                <div className="password-container">
                    <LargeInputWithTextOnTop
                        upText="Create your password"
                        helperTag='true'
                        onChange={e => setPasswordLocal(e.target.value)}
                        error={pwError}
                        autoComplete="new-password"
                        minWidth={minWidth}
                        setError={setPwError.bind(this)}
                        placeholder="Please enter your password"
                        type="password"/>
                </div>

                <div className="password-repete-container mt-2">
                    <LargeInputWithTextOnTop
                        upText="Retype your password"
                        onChange = {e => setConfirmPassword(e.target.value)}
                        error = {confError}
                        autoComplete="new-password"
                        setError = {setConfError.bind(this)}
                        placeholder="Please retype your password"
                        type="password"/>
                </div>
            </div>

            <BackAndContinueBtns
                continueHandler = {() => {continueHandler2()}}
                backHandler = {() => {window.open("/formProfession", "_self")}}/>

            <div className="already-container-signupPage w-100">
            <Link
                id="next" 
                to={{
                    pathname: "/congrats",
                    state: passwordLocal
                }}/>
                <AlreadyHaveAnAccount
                    text="Already have an account?"
                    textGold=" Sign In"/>
            </div>

            </div>
        </div>
    );
}