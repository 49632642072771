import { Slider } from '@mui/material';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ContinueBtn } from '../Global/components/buttons';
import { LoadingScreen, PopupWithInput } from '../Global/components/reusable';
import { API, Data, TokenData, TokenValidation } from '../Global/components/utils';
import './cardSecurity.css';
import './cardSettings.css';

export default function CardSettings({openModal}){
    const [modalPosition, setModalPosition] = useState("fixed");
    const [error, setError] = useState();
    const [pinPopup, setPinPopup] = useState();
    const [timerError, setTimerError] = useState();
    const [loading, setLoading] = useState(false);

    const [maxCountPerWeek, setCountMaxPerWeek] = useState(null);
    const [maxCountPerDay, setCountMaxPerDay] = useState(null);
    const [maxSpendPerTransaction, setMaxSpendPerTransaction] = useState(null);
    const [maxSpendPerDay, setMaxSpendPerDay] = useState(null);
    const [maxSpendPerWeek, setMaxSpendPerWeek] = useState(null);
    const [maxSpendPerMonth, setMaxSpendPerMonths] = useState(null);
    const [maxCountPerMonth, setMaxCountPerMonths] = useState(null);

    const [currentCountPerWeek, setCountCurrentPerWeek] = useState(null);
    const [currentCountPerDay, setCountCurrentPerDay] = useState(null);
    const [currentSpendPerTransaction, setCurrentSpendPerTransaction] = useState(null);
    const [currentSpendPerDay, setCurrentSpendPerDay] = useState(null);
    const [currentSpendPerWeek, setCurrentSpendPerWeek] = useState(null);
    const [currentSpendPerMonth, setCurrentSpendPerMonths] = useState(null);
    const [currentCountPerMonth, setCurrentCountPerMonths] = useState(null);

    const [initialCountPerWeek, setInitialCountPerWeek] = useState(null); 
    const [initialCountPerDay, setInitialCountPerDay] = useState(null);
    const [initialSpendPerTransaction, setInitialSpendPerTransaction] = useState(null);
    const [initialSpendPerDay, setInitialSpendPerDay] = useState(null);
    const [initialSpendPerWeek, setInitialSpendPerWeek] = useState(null);
    const [initialSpendPerMonth, setInitialSpendPerMonth] = useState(null);
    const [initialCountPerMonth, setInitialCountPerMonth] = useState(null);

    const modalRef = useRef();
    const history = useHistory();

    function HandleResize(){
        if(modalRef.current !== null){
            if(modalRef.current.clientHeight + 20 > window.innerHeight){
                setModalPosition("absolute");
            }
            else{
                setModalPosition("fixed");    
            }
        }
    }

    function HandleContinue(){
        console.log(maxCountPerWeek);
        console.log(maxCountPerDay);
        console.log(maxSpendPerDay);
        console.log(maxSpendPerMonth);
        console.log(maxSpendPerTransaction);
        console.log(maxSpendPerWeek);
        openModal("");
    }

    function HandleMaxType(rule){
        switch(rule.type){
            case "MaxCountPerDay":{
                setCountMaxPerDay(rule.maximum);
                break;
            }
            case "MaxCountPerMonth":{
                setMaxCountPerMonths(rule.maximum);
                break;
            }
            case "MaxCountPerWeek":{
                setCountMaxPerWeek(rule.maximum);
                break;
            }
            case "MaxSpendPerTransaction":{
                setMaxSpendPerTransaction(rule.maximum);
                break;
            }
            case "MaxSpendPerDay":{
                setMaxSpendPerDay(rule.maximum);
                break;
            }
            case "MaxSpendPerWeek":{
                setMaxSpendPerWeek(rule.maximum);
                break;
            }
            case "MaxSpendPerMonth":{
                setMaxSpendPerMonths(rule.maximum);
                break;
            }
        }
    }

    function HandleCurrentType(rule){
        switch(rule.type){
            case "MaxCountPerDay":{
                setCountCurrentPerDay(rule.value);
                setInitialCountPerDay(rule.value);
                break;
            }
            case "MaxCountPerMonth":{
                setCurrentCountPerMonths(rule.value);
                setInitialCountPerMonth(rule.value);
                break;
            }
            case "MaxCountPerWeek":{
                setCountCurrentPerWeek(rule.value);
                setInitialCountPerWeek(rule.value);
                break;
            }
            case "MaxSpendPerTransaction":{
                setCurrentSpendPerTransaction(rule.value);
                setInitialSpendPerTransaction(rule.value);
                break;
            }
            case "MaxSpendPerDay":{
                setCurrentSpendPerDay(rule.value);
                setInitialSpendPerDay(rule.value);
                break;
            }
            case "MaxSpendPerWeek":{
                setCurrentSpendPerWeek(rule.value);
                setInitialSpendPerWeek(rule.value);
                break;
            }
            case "MaxSpendPerMonth":{
                setCurrentSpendPerMonths(rule.value);
                setInitialSpendPerMonth(rule.value);
                break;
            }
        }
    }

    async function LoadData(){
        if(await TokenValidation()){
            axios.get(API.cardRules, {
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                res.data.items.forEach(rule => HandleMaxType(rule))
            })
            .catch(err => {
                console.log("ERROR loading card rules\n", err.response);
            })
        }
        else{
            history.replace("/pinCheck");
        }
    }

    async function SetRule(rule, value, encryptedPin, cardId){
        return(
            axios.post(API.setCardRules, {
                "value": value,
                "type": rule
            },
            {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                },
                params:{
                    userPin: encryptedPin,
                    cardId: cardId
                }
            })
        );
    }

    async function CheckRules(rules, rulesValues, encryptedPin, cardId){
        if(rulesValues[0] != initialCountPerWeek)
            await SetRule(rules[0], rulesValues[0], encryptedPin, cardId);
        
        if(rulesValues[1] != initialCountPerDay)
            await SetRule(rules[1], rulesValues[1], encryptedPin, cardId);
        
        if(rulesValues[2] != initialSpendPerWeek)
            await SetRule(rules[2], rulesValues[2], encryptedPin, cardId);
           
        if(rulesValues[3] != initialSpendPerTransaction)    
            await SetRule(rules[3], rulesValues[3], encryptedPin, cardId);

        if(rulesValues[4] != initialSpendPerDay)
            await SetRule(rules[4], rulesValues[4], encryptedPin, cardId);

        if(rulesValues[5] != initialSpendPerMonth)
            await SetRule(rules[5], rulesValues[5], encryptedPin, cardId);

        if(rulesValues[6] != initialCountPerMonth)
            await SetRule(rules[6], rulesValues[6], encryptedPin, cardId);
    }
    
    async function ConfirmAction(pin){
        let rules = ["MaxCountPerWeek", "MaxCountPerDay", "MaxSpendPerWeek", "MaxSpendPerTransaction",
             "MaxSpendPerDay", "MaxSpendPerMonth", "MaxCountPerMonth"];
        
        let rulesValues = [currentCountPerWeek, currentCountPerDay, currentSpendPerWeek, currentSpendPerTransaction,
                            currentSpendPerDay, currentSpendPerMonth, currentCountPerMonth];
                        
        if(await TokenValidation()){
            var SHA256 = require('js-sha256').sha256;
            var encryptedPin = SHA256(pin);
            const cardId = sessionStorage.getItem(Data.cardId);
            let nonZero = true;
            rulesValues.forEach(value => {
                if(value == 0){
                    setError("Max value cannot be 0");
                    clearTimeout(timerError);
                    setTimerError(setTimeout(() => {setError("")}, 5000))
                    nonZero = false;
                }
            })
            if(nonZero){
                try{
                    setLoading(true);
                    await CheckRules(rules, rulesValues, encryptedPin, cardId);
                    setError("");
                    openModal("");
                }
                catch(err){
                    console.log("ERROR setting the cards limits\n" ,err.response); 
                    setError("One or more fields failed to set");
                    clearTimeout(timerError);
                    setTimerError(setTimeout(() => {setError("")}, 5000))
                }
            }
            setLoading(false);
            setPinPopup(false);
        }
        else{
            history.replace("/pinCheck");
        }
        setPinPopup(false);
    }

    async function GetCurrentCardRules(){
        const cardId = sessionStorage.getItem(Data.cardId);
        setLoading(true);
        axios.get(API.cardsUrl, {
            headers:{
                Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
            }
        })
        .then(res => {
            let currentOpenedCard;
            
            res.data.forEach(card => {
                if(card.id === cardId){
                    currentOpenedCard = card;
                }
            })
            setLoading(false);
            currentOpenedCard.rules.forEach(rule => HandleCurrentType(rule))
        })
        .catch(err => {
            setLoading(false);
            console.log("ERROR getting the card list\n", err.response !== undefined ? err.response : err);
        })
    }

    useEffect(async () => {
        HandleResize();
        window.addEventListener("resize", HandleResize);
        await GetCurrentCardRules();
        await LoadData();
        return () => window.removeEventListener("resize", HandleResize);
    }, [])    

    return(
        <div className="card-settings-container">
            {loading && (
                <LoadingScreen/>
            )}
            <div ref={modalRef} style={{position: modalPosition}} className="modal-container">   
                <button 
                    onClick={openModal.bind(this, "")}
                    className="close-btn">&times;</button>
                <div className="header">
                    <div className="title-container">
                        <span className="title1">Card Settings</span>
                        <span className="subtitle1">Change card limits</span>
                    </div>
                    <div className="card-container">
                        <img 
                            src={process.env.PUBLIC_URL + "/Images/wolves-card.jpg"}
                            className="card-logo"/>
                    </div>
                </div>

                <div className="body d-flex flex-column">
                    <div className="double-input-container">
                        <div className="left-input">
                            <SliderWithTitle 
                                setValue={e => setCountCurrentPerDay(e)}
                                initialValue={currentCountPerDay}
                                max={maxCountPerDay}
                                title="Card max count per day"/>
                        </div>

                        <div className="right-input">
                            <SliderWithTitle 
                                setValue={e => setCurrentSpendPerDay(e)}
                                initialValue={currentSpendPerDay}
                                max={maxSpendPerDay}
                                title="Card max spend per day"/>
                        </div>
                    </div>

                    <div className="double-input-container">
                        <div className="left-input">
                            <SliderWithTitle 
                                setValue={e => setCountCurrentPerWeek(e)}
                                initialValue={currentCountPerWeek}
                                max={maxCountPerWeek}
                                title="Card max count per week"/>
                        </div>

                        <div className="right-input">
                            <SliderWithTitle 
                                setValue={e => setCurrentSpendPerWeek(e)}
                                initialValue={currentSpendPerWeek}
                                max={maxSpendPerWeek}
                                title="Card max spend per week"/>
                        </div>
                    </div>

                    <div className="double-input-container">
                        <div className="left-input">
                            <SliderWithTitle
                                setValue={e => setCurrentCountPerMonths(e)}
                                initialValue={currentCountPerMonth}
                                max={maxCountPerMonth}
                                title = "Card max count per month"/>
                        </div>

                        <div className="right-input">
                            <SliderWithTitle 
                                setValue={e => setCurrentSpendPerMonths(e)}
                                initialValue={currentSpendPerMonth}
                                max={maxSpendPerMonth}
                                title="Card max spend per month"/>
                        </div>
                    </div>

                    <div className="double-input-container">
                        <div className="left-input">
                            <SliderWithTitle 
                                setValue={e => setCurrentSpendPerTransaction(e)}
                                initialValue={currentSpendPerTransaction}
                                max={maxSpendPerTransaction}
                                title="Card max spend per transaction"/>
                        </div>
                    </div>
                    <span style={{height: '24px'}} className="error-message">{error}</span>
                    <div 
                        onClick={() => setPinPopup(true)}
                        className="continue-btn">
                        <ContinueBtn message="Save Settings"/>
                    </div>
                </div>

                {pinPopup && (
                    <PopupWithInput
                        overlayStyle={{borderRadius: '15px'}}
                        title="Please insert your pin to continue"
                        confirmAction={pin => ConfirmAction(pin)}
                        denyAction = {() => setPinPopup(false)}/>
                )}
            </div>
            <div className="overlay"/>
        </div>
    );
}

function SliderWithTitle({min=0, max=1000, title = "Slider", initialValue = 10, setValue = (e) => {}}){
    const [step, setStep] = useState(5);

	function valuetext(value) {
		return `${value}£`;
	}
    
    useEffect(() => {
        if(max < 2000){
            setStep(1);
        }
        else{
            setStep(Math.max((max - min) / 2000, 5));
        }
    }, [])

    return(
        <div className="d-flex flex-column my-2">
            <span className="slider-title">
                {title}
            </span>
            <div className="slider-container">
                <div className="slider-body">
                    <div style={{paddingRight: 10}} className="limits-indicator">{min}</div>
                    <div className="slider">
                        <Slider
                            key={initialValue !== null ? "loaded" : "loading"}
                            onChangeCommitted = {(e, val) => setValue(val)}
                            aria-label="Custom marks"
                            defaultValue={initialValue}
                            valueLabelFormat={valuetext}
                            valueLabelDisplay="auto"
                            step={step}
                            min={min}
                            max={max}
                            sx={{
                                color: "#FDB913",
                                '& .MuiSlider-valueLabel':{
                                    background: "#FDB913",
                                    fontFamily: 'FSAlbertPro',
                                    color: "#000"
                                }
                            }}/>
                    </div>
                    <div style={{paddingLeft: 10}} className="limits-indicator">{max}</div>
                </div>
                <div className="d-flex justify-content-between">
                </div>
            </div>
        </div>
    );
}