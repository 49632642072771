import {AlreadyHaveAnAccount, LoadingScreen, MiniLogo, PageTitle} from '../Global/components/reusable'
import {ContinueBtn, LargeInputWithTextOnTop} from '../Global/components/buttons';
import './signin.css'
import { Link } from 'react-router-dom';
import SlideShow from '../Global/components/slideshow';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API, SessionVars, TokenData } from '../Global/components/utils';

export default function SiguInPage(props){

    console.log(props.history.location.flag);
    return(
        <div className="container-fluid row main m-0 p-0">
            <div 
                className="slideshow-container-main d-flex justify-content-center col-md-5 p-0 m-0">
                <SlideShow/>
            </div>
            <RightSide flag = {props.history.location.flag}/>
        </div>
    );
}

function RightSide(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [nextPage, setNextPage]  = useState("");
    const [message, setMessage] = useState("");

    let obj = {
        email: email,
        password: password,
        message: message
    }

    function kycStatus(token){
        axios.get(API.kycStatusUrl, {
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then(res => {
            if(res.data.status === "Unknown" || res.data.status === "Denied")
            {
                setNextPage("/kyc");
            }
            else if(res.data.status === "Pending")
            {
                setMessage("Your identity is in proccess of verification. This might take 24-48h")
                setNextPage("/signDone");   
            } 
            else{
                axios.get(API.pinStatustUrl, {
                    headers:{
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(res => {
                    console.log(res.data);
                    if(res.data === "Unknown"){
                        setNextPage("/pin");
                    }
                    else if(res.data === "Stored"){
                        setNextPage("/pinCheck")
                    }
                    else{
                        setMessage("Your pin is in proccess of validation");
                        setNextPage("/signDone");
                    }
                })
                .catch(err => {
                    console.log("Pin status error\n", err.response);
                    if(err.response.status == 400)
                        setError("Invalid email/password combination");
                    else if (err.response.status == 403)
                    {
                        setMessage("Bank provider not assinged yet, try again later");
                        setNextPage("/signDone");
                    }
                    else
                        setError("Server might be down right now. Please try a little later");
                });
            }
        })
        .catch(err => {
            console.log("kyc error\n", err.response);
        })
    }

    function login(){
        const objLogin = {
            grantType: "password",
            username: email,
            password: password,
            scope: "offline_access email BankingMiddleware openid",
            clientId: "BankingMiddleware_App",
            clientSecret: "tKD2MySpVx7Sc8k2Gnt3BYWmmNNSHQUx"
        }
        
        axios.get(API.openConfigurationUrl, objLogin)
        .then(res => {
            sessionStorage.setItem(SessionVars.appName, email);
            localStorage.setItem(SessionVars.revocationEndpoint, res.data.revocation_endpoint);
            console.log(res.data.revocation_endpoint);
            const tokenEndpoint = res.data.token_endpoint;
            var qs = require('qs');
            var data = qs.stringify({
                'grant_type': 'password',
                'username': email,
                'password': password,
                'scope': 'offline_access email BankingMiddleware openid',
                'client_id': 'BankingMiddleware_App',
                'client_secret': 'tKD2MySpVx7Sc8k2Gnt3BYWmmNNSHQUx' 
            });
            
            var config = {
                method: 'post',
                url: API.connectTokenUrl,
                headers: { 
                    'content-type': 'application/x-www-form-urlencoded',
                    'Cookie': 'XSRF-TOKEN=CfDJ8AbOxKfo0HtBmmCKrRDFJqgO46BOrDBVdggsJrzzxzBbKyCBhtNUSTmGPLjkvg1jtqKnqp7xd_Z9GHgCSeqdH5KrO4bpDCry_DBDWgsISKfa70_2tf-2sHcD8wOyAps9UOx23WY7Evl-LDefFY5sFwA'
                },
                data : data
            };

            axios(config)
            .then(response => {
                const date = new Date();
                sessionStorage.setItem(TokenData.token, response.data.access_token);
                sessionStorage.setItem(TokenData.refreshToken, response.data.refresh_token);
                sessionStorage.setItem(TokenData.createdAt, date.getTime());
                sessionStorage.setItem(TokenData.tokenLifespan, response.data.expires_in);
                localStorage.setItem(SessionVars.appName, email);
                
                setError("");
                kycStatus(response.data.access_token);
            })
            .catch(error => {
                console.log("Token error: ", error.response);
                if(error.response.data?.error_description !== undefined){
                    setError(error.response.data.error_description);
                }
                else if(error.response.status >= 400 && error.response.status < 500)
                    setError("Invalid email/password combination");
                else
                    setError("Server might be down right now. Please try a little later");
            });
        })
        .catch(err =>{
            setError("Invalid email/password combination");
            console.log(err)
        })
    }

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();

        if(props.flag === true){
            document.getElementById("info-popup").style.opacity = 1;
        }
    }, [])

    useEffect(() => {
        if(error != ""){
            setTimeout(() => {setError("")}, 5000);
        }
    }, [error])

    useEffect(() =>{
        if(nextPage != "")
            document.getElementById("next").click();
    }, [nextPage])

    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row">
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>

                    <div className="page-title-container">
                        <PageTitle
                            title="Sign In"
                            message="Enter your details below to sign in"/>
                    </div>
                </div>
                
                <div>
                    <div className="email-container">
                        <LargeInputWithTextOnTop
                            onChange = {e => setEmail(e.target.value)}
                            upText="Email address"
                            placeholder="Please enter your email address"/>
                    </div>

                    <div className="pw-container">
                        <LargeInputWithTextOnTop
                            upText="Password"
                            onChange={e => setPassword(e.target.value)}
                            placeholder="Please enter your password"
                            type="password"/>
                    </div> 
                <div style={{height: '16px'}} className="error-message align-self-center">{error}</div>
                </div>

                <div className='d-flex flex-column justify-content-between' style={{height: '140px'}}>
                    <div className="continue-btn-container-signupPage">
                        <Link id="next" 
                            to={{
                                pathname: `${nextPage}`,
                                state: obj
                            }}/>
                        <ContinueBtn onClick = {() => {login()}}/>
                    </div>

                    <div id="info-popup" className='info-popup-x'>
                        <div>
                            <img width={24} height={24} src={process.env.PUBLIC_URL + "./Images/Icons/checkmark.png"}/>
                        </div>
                        <div style={{ padding: '0 10px', textAlign:'center'}}>
                            Email sent. Please check email for reset instructions
                        </div>
                        <div style={{cursor: 'pointer'}}  onClick={() => document.getElementById("info-popup").style.opacity = 0}>
                            <img width={24} height={24} src={process.env.PUBLIC_URL + "./Images/Icons/cancel.png"}/>
                        </div>
                    </div>
                </div>
                

                <div className="already-container-signupPage w-100">
                    <AlreadyHaveAnAccount
                        link="/reset_password"
                        text="Forgot password?"
                        textGold=" Click here!"/>
                </div>
                
            </div>
        </div>
    );
}