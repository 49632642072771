import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './landing.css';
import { DownloadContainer, StickyHeader } from '../Global/components/StickyHeader';
import { Data, SessionVars } from '../Global/components/utils';

export default function LandingPage(){
    const photoesRef = useRef(null);

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const phoneCardRef = useRef(null);
    const cardsRef = useRef(null);
    const history = useHistory();

    const observerWith2Kids = new IntersectionObserver(
        ([entry]) => {
            if(entry.isIntersecting){
                entry.target.children[0].classList.add("active-left");
                entry.target.children[1].classList.add("active-right");
            }
        }, { threshold: 0.1 }
    );

    const observphoneCardObserver = new IntersectionObserver(
        ([entry]) => {
            if(entry.isIntersecting){
                entry.target.childNodes[0].classList.add("active-left");
                if(window.innerWidth < 610){
                    entry.target.childNodes[1].classList.add("active-left-delayied-small");
                }
                else{
                    entry.target.childNodes[1].classList.add("active-left-delayied");
                }
            }
        }, { threshold: 0.1 }
    );

    const observerCards = new IntersectionObserver(
        ([entry]) => {
            if(entry.isIntersecting){
                entry.target.childNodes[2].classList.add("active-right");
                entry.target.childNodes[1].classList.add("active-right-delay1");
                entry.target.childNodes[0].classList.add("active-right-delay2");
            }
        }
    );

    const observerSection1 = new IntersectionObserver(
        ([entry]) => {
            if(entry.isIntersecting){
                entry.target.classList.add("active-right");
            }
        }, { threshold: 0.1 }
    );

    const observerSection2 = new IntersectionObserver(
        ([entry]) => {
            if(entry.isIntersecting){
                entry.target.children[0].classList.add("active-left");
            }
        }, { threshold: 0.4 }
    );

    //CHECK IF ELEMENTS ARE CURRENTLY DISPLAIED
    useEffect(() => {
        window.scrollTo(0, 0);
        observerWith2Kids.observe(photoesRef.current);
        observphoneCardObserver.observe(phoneCardRef.current);
        observerSection1.observe(section1Ref.current);
        observerSection2.observe(section2Ref.current);
        observerCards.observe(cardsRef.current);
        return() => { 
            observerWith2Kids.disconnect();
            observphoneCardObserver.disconnect();
            observerSection1.disconnect();
            observerSection2.disconnect();
            observerCards.disconnect();
        }
    }, [])

    return(
        <div className="landing-page-container">
            
            <StickyHeader/>
            <script id="CookieDeclaration" src="https://consent.cookiebot.com/c085c010-944c-4132-bb06-d5c87d0d096c/cd.js" type="text/javascript" async></script>
            <div ref={photoesRef} className="top-photoes">
                <div className="landing-left">
                    <div className="info">
                        <span className="text2">
                           Wolves Official Debit Card
                        </span>

                        <span className="text3">
                            Powered by REPX
                        </span>

                        <span style={{color: '#fff', fontFamily: 'FSAlbertPro', margin: '15px 0'}}>
                            Download the Wolves Official Debit Card App, and order your card straight from the app in minutes!
                        </span>


                        <div className="download-icons">
                            <img width={150} src={process.env.PUBLIC_URL + "Images/Icons/google-play.png"}/>
                            <img width={150} style={{marginLeft: '40px'}} src={process.env.PUBLIC_URL + "Images/Icons/app-store.png"}/>
                        </div>
                    </div>
                </div>
                <div className="landing-right">
                    <img src={process.env.PUBLIC_URL + "/Images/Landing/phones-card.png"}/>
                </div>
            </div>
        
            <div className="section1">
                <span className="title">Benefits of the Official Wolves Debit Card</span>
                <div className="icons">
                    <div className="icon">
                        <img 
                            src={process.env.PUBLIC_URL + "/Images/Landing/gift-icon.png"}/>
                        <span className="text">Access exclusive prizes and special offers from across the club when you sign up.</span>
                    </div>

                    <div className="icon">
                        <img 
                            src={process.env.PUBLIC_URL + "/Images/Landing/phone-icon.png"}/>
                        <span className="text">A dedicated app to manage your card.</span>
                    </div>

                    <div className="icon">
                        <img 
                            src={process.env.PUBLIC_URL + "/Images/Landing/card-icon.png"}/>
                        <span className="text">A specially designed card to make you stand out from the crowd.</span>
                    </div>
                </div>
            </div>
        
            <div className="section-split">
                <div  className="landing-left">
                    <div className="background">
                        <div className="repx">The card is proposed by REPX — The Reputation Exchange PLC in collaboration with Wolverhampton.</div>
                        <div ref={phoneCardRef} className="phone-card">
                            <img className="phone" src={process.env.PUBLIC_URL + "/Images/Landing/phone-transactions.png"}/>
                            <img className="card" width="350" src= {process.env.PUBLIC_URL + "/Images/wolves-card-rounded.png"}/>
                        </div>
                        {/* <img src={process.env.PUBLIC_URL + "/Images/Landing/phone+card2.png"}/> */}
                    </div>
                </div>
                <div ref={section1Ref} className="landing-right">
                    <div className="text-container">
                        <div className="title">The Official Wolves Debit Card in association with REPX</div>
                        <div className="text1">
                            <br/>
                            Help manage your day-to-day expenditure with the Official Wolves Debit Card. Order your card today, 
                            link it to your bank account and simply top up to help you manage and stay in control.<br/><br/>
                        </div>
                        
                        <div className="text1">
                            Or why not gift a card to a young Wolves Supporter? The card can be preloaded with a set amount by adults, 
                            with the exclusive app used to monitor where the card is used.  
                            <br/><br/>

                            The card can be used as a normal Visa card, giving supporters the ability to use the cards contactless 
                            technology, withdraw cash as well as using the card for online purchases.
                            <br/><br/>

                            What’s more, all card holders will be able to enter exclusive prize draws as well as accessing special 
                            offers across the club when they use their Official Wolves Debit Card. Purchase yours today.

                        </div>


                    </div>
                
                    <div className="icons">
                        <div className="icon">
                            <img src={process.env.PUBLIC_URL + "/Images/Landing/privacy.png"}/>
                            <span className="text">Your privacy is fully protected!</span>
                        </div>

                        <div className="icon">
                            <img src={process.env.PUBLIC_URL + "/Images/Landing/account.png"}/>
                            <span className="text">Unique card design</span>
                        </div>

                        <div className="icon">
                            <img src={process.env.PUBLIC_URL + "/Images/Landing/topup.png"}/>
                            <span className="text">Dedicated app to easily manage your account</span>
                        </div>

                        <div className="icon">
                            <img src={process.env.PUBLIC_URL + "/Images/Landing/security.png"}/>
                            <span className="text">Use like a normal debit or credit card</span>
                        </div>
                    </div>
                </div>
            </div>
        
             <div ref={section2Ref} className="section-split2">
                <div className="landing-left">
                    <div className="info-container">
                        <div className="text1">
                            Coming soon
                        </div>

                        <div className="text2">
                            Further details to be announced soon
                        </div>
                    </div>
                </div>

                <div className="landing-right">
                    <div className="background">
                        <div className="text">
                        The graphical representation of the card is given as an example only. Actual card may be different.
                        </div>

                        <div ref={cardsRef} className="cards">
                            <img className="card3" width="320" src={process.env.PUBLIC_URL + "/Images/wolves-card-rounded.png"}/>
                            <img className="card2" width="320" src={process.env.PUBLIC_URL + "/Images/wolves-card-rounded.png"}/>
                            <img className="card1" width="320" src={process.env.PUBLIC_URL + "/Images/wolves-card-rounded.png"}/>
                        </div>
                    </div>
                </div>
            </div>
            
            <DownloadContainer/>
            
            {/* <div ref={formContainer} className="section-split3">
                <div className="landing-left">
                    <div className="info-container">
                        <div className="title">
                            Fill out the form to receive more information!
                        </div>

                        <div className="message">
                            Request more information without obligation to obtain the Wolverhampton fans debit card!
                        </div>
                    </div>
                
                    <img src={process.env.PUBLIC_URL + "/Images/Landing/photo-submit.png"}/>
                </div>

                <div className="landing-right">
                    <div className="inputs-container">
                        <div className="input">
                            <div className="title">
                                Your name and surname*
                            </div>
                            <input onChange={e => setName(e.currentTarget.value)} placeholder="Please enter your name and surname"/>
                        </div>

                        <div style={{marginTop: '32px'}} className="input">
                            <div className="title">
                                Your email address*
                            </div>
                            <input onChange={e => setEmail(e.currentTarget.value)} placeholder="Please enter your email address"/>
                        </div>

                        <div style={{marginTop: '32px'}} className="input">
                            <div className="title">
                                Your phone*
                            </div>
                            <input onChange={e => setPhone(e.currentTarget.value)} placeholder="Please enter your phone"/>
                        </div>

                        <div className="agree-container">
                            <CustomCheckBox
                                isActive={agree}
                                setIsActive={setAgree.bind(this)}/>
                            <div className="agree-text">
                                I declare to accept the Privacy Policy
                            </div>
                        </div>

                        <div className="submit-btn">
                            <ContinueBtn onClick={handleSubmit.bind(this)} message="Submit"/>
                        </div>
                        <span className="error">{error}</span>
                    </div>
                </div>
            </div>
         */}
            
            <div className="footer">
                <div className="text-container">
                    <div className="text1">
                        THE REPUTATION EXCHANGE PLC Suite 2a1, Northside House, Mount Pleasant, Barnet, Hertfordshire, EN4 9EB - United Kingdom - Company Number 09977505
                    </div>

                    <div className="text2">
                        Privacy Policy | Cookie Policy
                    </div>
                </div>
            </div>
        </div>
    );
}