import { BackAndContinueBtns, ContinueBtn } from '../Global/components/buttons.js';
import LeftSide from '../Global/components/leftSide.js';
import { AlreadyHaveAnAccount, MiniLogo, PageTitle } from '../Global/components/reusable.js';
import {Link, useHistory} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API, TokenData, TokenValidation } from '../Global/components/utils.js';
import axios from 'axios';
import './kyc.css';
import { init } from 'onfido-sdk-ui';
var Onfido = require('onfido-sdk-ui');

export default function KycPage(props){
    return(
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide obj={props.location.state}/>
        </div>
    );
}

function CompleteKYC(username, password){
    var qs = require('qs');
    var data = qs.stringify({
        'grant_type': 'password',
        'username': `${username}`,
        'password': `${password}`,
        'scope': 'offline_access email BankingMiddleware openid',
        'client_id': 'BankingMiddleware_App',
        'client_secret': 'tKD2MySpVx7Sc8k2Gnt3BYWmmNNSHQUx' 
    });

    var config = {
        method: 'post',
        url: API.connectTokenUrl,
        headers: { 
            'content-type': 'application/x-www-form-urlencoded',
            'Cookie': 'XSRF-TOKEN=CfDJ8AbOxKfo0HtBmmCKrRDFJqgO46BOrDBVdggsJrzzxzBbKyCBhtNUSTmGPLjkvg1jtqKnqp7xd_Z9GHgCSeqdH5KrO4bpDCry_DBDWgsISKfa70_2tf-2sHcD8wOyAps9UOx23WY7Evl-LDefFY5sFwA'
        },
        data : data
    };

    axios(config)
    .then(response => {
        axios.post(API.kycServiceEndUrl, {}, {
            headers: {
                Authorization: `Bearer ${response.data.access_token}`
            }
        })
        .then(res => {
            document.getElementById("next").click();
        })
        .catch(err => {
            console.log("Error ending kyc proccess\n", err);
        }) 
    })
    .catch(error => {
        console.log("Token error: ", error);
        return false;
    });
}

function RightSide(obj){
    let username = "";
    let password = "";
    let token = "";

    const history = useHistory();
    useEffect(() => {
        console.log("BEGIN");
        if(obj.obj.email === undefined ){
            history.push("/signin");
        }
        else{
            username = obj.obj.email;
            password = obj.obj.password;
        }

        var qs = require('qs');
        var data = qs.stringify({
            'grant_type': 'password',
            'username': `${username}`,
            'password': `${password}`,
            'scope': 'offline_access email BankingMiddleware openid',
            'client_id': 'BankingMiddleware_App',
            'client_secret': 'tKD2MySpVx7Sc8k2Gnt3BYWmmNNSHQUx' 
        });

        var config = {
            method: 'post',
            url: API.connectTokenUrl,
            headers: { 
                'content-type': 'application/x-www-form-urlencoded',
                'Cookie': 'XSRF-TOKEN=CfDJ8AbOxKfo0HtBmmCKrRDFJqgO46BOrDBVdggsJrzzxzBbKyCBhtNUSTmGPLjkvg1jtqKnqp7xd_Z9GHgCSeqdH5KrO4bpDCry_DBDWgsISKfa70_2tf-2sHcD8wOyAps9UOx23WY7Evl-LDefFY5sFwA'
            },
            data : data
        };

        axios(config)
        .then(response => {
            token = response.data.access_token;

            axios.post(API.kycServiceBeginUrl, 
            {
                "applicationId" : null
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(res => {
                const SDK_TOKEN = res.data.payload.sdkToken;
                Onfido.init({
                    token: SDK_TOKEN,
                    containerId: 'onfido-mount',
                    onComplete: function (data) {
                        console.log('everything is complete');
                        CompleteKYC(username, password);
                    },
                    onError: function (err) {
                        console.log("Error onfido\n", err);
                    },
                    steps: ['welcome', 'document', 
                        {
                            type:"face",
                                options: {
                                    requestedVariant: "video",
                                }
                        }, 
                        'complete'],
                  })
            })
            .catch(err => console.log("Error getting SDK_TOKEN\n", err));
        })
        .catch(error => {
            console.log("Token error: ", error);
        });
    }, [])

    let dataToSend = {
        email: "",
        password: "",
        message: "Your information is being verified"
    }
    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row" style={{minHeight: '700px'}}>
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>
                </div>
                <div className='onfido-container'>
                    <div id="onfido-mount" className="kyc-container">
                </div>
                    
                </div>
                
                <Link 
                    id="next" 
                    to={{
                        pathname: '/signDone',
                        state: dataToSend
                    }}/>
            </div>
        </div>
    );
}