import { Dropdown } from "../Global/components/dropdown";
import LeftSide from "../Global/components/leftSide";
import { AlreadyHaveAnAccount, ImageRight, MiniLogo, PageTitle } from "../Global/components/reusable";
import {BackAndContinueBtns, IconTextBtnHorizontal, LargeInputWithTextOnTop} from '../Global/components/buttons';
import './formProfession.css'
import { useEffect, useState } from "react";
import axios from "axios";
import { SessionVars, SplitObjAtributs } from "../Global/components/utils";

export default function FormProfessionPage(){
    return(
        <div className="container-fluid row main p-0 m-0">
            <LeftSide/>
            <RightSide/>
        </div>
    );
}

function RightSide(){
    const [supporterNumber, setSupporterNumber] = useState("");
    

    function continueHandler(){
        sessionStorage.setItem(SessionVars.membershipNumber, supporterNumber);
        window.open("/formPassword", "_self");
    }
    return(
        <div className="col-lg-7 p-0 m-0 d-flex right row" style={{minHeight: '540px'}}>
            <div className="d-flex flex-column align-self-stretch align-content-center justify-content-between px-lg-5 col-sm-8 col-9 col-lg-11">
                <div className="d-flex flex-lg-column flex-column align-items-center align-items-lg-start justify-content-center">
                    <div>
                        <MiniLogo/>
                    </div>
                
                    <div className="page-title-container">
                        <PageTitle
                            title="Sign Up"
                            message="Let's get started!"/>
                    </div>
                </div>
                <span style={{fontSize: '20px', fontFamily: 'FSAlbertPro'}}>If you have a supporter number, please enter it here (optional)</span>

                <div className="d-flex flex-column align-items-center">
                    <div className="membership-container">
                        <LargeInputWithTextOnTop
                            upText="Supporter number"
                            onChange = {e => setSupporterNumber(e.target.value)}
                            placeholder="Please enter your Supporter Number to link with your wolves account"/>
                        <a 
                            style={{fontSize: 12, textDecoration: 'none', paddingLeft: 3}}
                            href="https://www.wolves.co.uk/fans/wolves-account-set-up-guide/" target='_blanck'>
                            <div style={{color: '#000', fontFamily:'FSAlbertProBold'}}>
                                Don’t have a supporter number? Click 
                                <span style={{display:'inline-block', color: '#FDB913', fontFamily:'FSAlbertProBold'}}>
                                    &nbsp;here&nbsp;
                                </span>
                                to find out how to setup a Wolves Account and create one
                            </div>
                            
                        </a>
                    </div>
                </div>

                <BackAndContinueBtns
                    backHandler= {() => {window.open("/formLocation", "_self")}}
                    continueHandler = {() => {continueHandler()}}/>
                
                <div className="already-container-signupPage w-100">
                    <AlreadyHaveAnAccount
                        text="Already have an account?"
                        textGold=" Sign In"/>
                </div>
            </div>
        </div>
    );
}

