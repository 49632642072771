import axios from 'axios';
import { useEffect, useState } from 'react';
import { ContinueBtn } from '../Global/components/buttons';
import { CustomCheckBox, ErrorWithTimer, LoadingScreen } from '../Global/components/reusable';
import { API, TokenData, TokenValidation } from '../Global/components/utils';
import { useHistory } from 'react-router-dom';
import './settings.css';

export default function Settings(){
    const [sysNotWolves, setSysNotWolves] = useState(false);
    const [emailWolves, setEmailWolves] = useState(false);
    const [sysNotGeneral, setSysNotGeneral] = useState(false);
    const [emailGenerel, setEmailGeneral] = useState(false);
    const [notificationId, setNotificationId] = useState(0);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    function ProcessPreferences(general = [], marketing = []){
        if(general[0] !== "None"){
            if(general.length === 2){
                setSysNotGeneral(true);
                setEmailGeneral(true);
            }

            if(general.length === 1){
                if(general[0] === "Email")
                    setEmailGeneral(true);
                else
                    setSysNotGeneral(true);
            }
        }
        
        if(marketing[0] !== "None"){
            if(marketing.length === 2){
                setSysNotWolves(true);
                setEmailWolves(true);
            }
            
            if(marketing.length === 1){
                if(marketing[0] === "Email")
                    setEmailWolves(true);
                else
                    setSysNotWolves(true);
            }
        }
        
    }

    async function Save(){
        if(await TokenValidation()){
            let general = [];
            let promo = [];

            if(emailGenerel === true){
                general.push("Email");
            }

            if(sysNotGeneral === true){
                general.push("PushNotification");
            }

            if(emailWolves === true){
                promo.push("Email");
            }

            if(sysNotWolves === true){
                promo.push("PushNotification");
            }

            if(general.length === 0)
                general.push("None");

            if(promo.length === 0)
                promo.push("None");

            general = general.toString()
            promo = promo.toString();
        
            axios.put(API.setNotificationSubscriptionsUrl, {
                general: general,
                localMarketing: promo,
                thirdPartyMarketing: "None"
            },
            {
                params:{
                    id: notificationId
                }, 
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                history.push("/news");
            })
            .catch(err => {
                console.log("Error uploading new settings\n", err);
                setError("Error saving you preferences, please try again");
            })
        }
        else{
            history.replace("/pinCheck");
        }
    }

    useEffect(async () => {
        setIsLoading(true);
        if(await TokenValidation()){
            axios.get(API.myNotificationSubscriptionsUrl, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                setNotificationId(res.data.id);
                var general = res.data.general;
                var localMarketing = res.data.localMarketing;
                var generalArr = [];
                var localMarketingArr = [];

                if(general.includes(',')){
                    generalArr = general.split(',');
                }
                else{
                    generalArr.push(general);
                }

                if(localMarketing.includes(',')){
                    localMarketingArr = localMarketing.split(',');
                }
                else{
                    localMarketingArr.push(localMarketing);
                }
                    
                ProcessPreferences(generalArr, localMarketingArr);
                setIsLoading(false);
            })
            .catch(err => {
                console.log("Error getting notification preferences",  err);
                setError("Error loading data, please try to refresh the page");
                setIsLoading(false);
            })
        }
        else{
            history.replace("/pinCheck");
        }
    }, [])

    return(
        <div className="setting-page-container">
            {isLoading && (<LoadingScreen position="absolute"/>)}
            <span className="settings-title">Settings</span>
            <div className="group">
                <span className="group-title">Wolves Promotional</span>
                <div className="item">
                    <span>System notification</span>
                    <CustomCheckBox isActive={sysNotWolves} setIsActive={setSysNotWolves}/>
                </div>

                <div className="item">
                    <span>Email</span>
                    <CustomCheckBox isActive={emailWolves} setIsActive={setEmailWolves}/>
                </div>
            </div>

            <div className="group">
                <span className="group-title">General</span>
                <div className="item">
                    <span>System notification</span>
                    <CustomCheckBox isActive={sysNotGeneral} setIsActive={setSysNotGeneral}/>
                </div>

                <div className="item">
                    <span>Email</span>
                    <CustomCheckBox isActive={emailGenerel} setIsActive={setEmailGeneral}/>
                </div>
            </div>
            <ErrorWithTimer setError={setError.bind(this)} error = {error}/>
            <div
                onClick = {() => Save()}
                className="save-btn-container">
                <ContinueBtn message="Save"/>
            </div>
        </div>
    );
}