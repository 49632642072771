import axios from 'axios';
import { cloneElement, useEffect, useRef, useState } from 'react';
import { ContinueBtn, LargeInputWithTextOnTop } from '../Global/components/buttons';
import { API,  Data,  GetCardId,  TokenData, TokenValidation } from '../Global/components/utils';
import { useHistory } from 'react-router-dom';
import './cardSecurity.css';
import { LoadingScreen, PopupWithInput } from '../Global/components/reusable';
import { meaWallet } from '../Global/meaWallet';

export default function CardSecurity({openModal}){
    const [modalPosition, setModalPosition] = useState("fixed");
	const [error, setError] = useState("");
	const [timerIdError, setTimerIdError] = useState("");
    const [timerIdPopup, setTimerIdPopup] = useState(null);
    const [cardPin, setCardPin] = useState("****");
    const [inputType, setInputType] = useState("password");
    const [resetModal, setResetModal] = useState(false);
    const [showModalPin, setShowModalPin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModalCardData, setShowModalCardData] = useState(false);

    const refModal = useRef();
	const history = useHistory();

    function HandleResize(){
        if(refModal.current.clientHeight + 20 > window.innerHeight){
            setModalPosition("absolute");
        }
        else{
            setModalPosition("fixed");    
        }
    }

    useEffect(() => {
        HandleResize();
        window.addEventListener("resize", HandleResize);
        return () => window.removeEventListener("resize", HandleResize);
    }, [])

	useEffect(() => {
		if(timerIdError !== null){
			clearTimeout(timerIdError);
		}

		if(error.length > 0){
			setTimerIdError(setTimeout(() => {
				setError("")
			}, 5000));
		}
	}, [error])

    async function ShowPin(pin){
        if(inputType === "password"){
            setIsLoading(true);
            if(await TokenValidation()){    
                var SHA256 = require("js-sha256").sha256;
                let encryptedPin = SHA256(pin);
                axios.get(API.getCardPinUrl, {
                    params: {
                        userPin: encryptedPin,
                        cardId: sessionStorage.getItem(Data.cardId)
                    },
                    headers:{
                        Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                })
                .then(res => {
                    setInputType("text");
                    setCardPin(res.data);
                    setShowModalPin(false);
                    setIsLoading(false);
                })
                .catch(err => {
                    console.log("ERROR getting card pin\n", err.response);
                    setShowModalPin(false);
                    setIsLoading(false);

                    if(err.response.status === 403){
                        setError("Invalid pin provied");
                    }
                    else{
                        setError("Some error has occured");
                    }
                })
            }
            else{
                history.replace("/pinCheck");
            }
        }
        else{
            setInputType("password");
            setCardPin("****");
        }
    }

    async function ResetPin(pin){
        if(await TokenValidation()){
            var res = await GetCardId();

            if(res.data.length > 0){
                const id = res.data[0].id;
                var SHA256 = require("js-sha256").sha256;
                let encryptedPin = SHA256(pin);

                axios.post(API.resetCardPin, {}, {
                    params: {
                        userPin: encryptedPin,
                        cardId: id
                    },
                    headers:{
                        Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                })
                .then(res => {
                    const elem = document.getElementById("info-popup");
                    
                    if(timerIdPopup !== null){
                        clearTimeout(timerIdPopup);
                    }
                    elem.classList.add("info-pop-active");
                    
                    setTimerIdPopup(setTimeout(()=> {
                        elem.classList.remove("info-pop-active");
                        setTimerIdPopup(null);
                    }, 5000));

                    setResetModal(true);
                })
                .catch(err => {
                    console.log("ERROR reseting the pin card pin\n", err.response);
                    if(err.response.status === 403){
                        setError("Invalid pin provided");
                    }
                    else if(err.response.status >= 500){
                        setError("Some error happend on the server");
                    }
                    else{
                        setError("Some unexpected error");
                    }
                    setResetModal(false);
                })
            }
            else{
                setError("No card found");
            }
        }
        else{
            history.replace("/app");
        }
    }

    async function showCardData(){
        setIsLoading(true);
        
        try{
            var cardData = await meaWallet();
            console.log(cardData)
        }
        catch (err){
            console.log(err);
        }
        setShowModalCardData(false);
        setIsLoading(false);
    }

    return(
        <div>
            {isLoading && (<LoadingScreen position='fixed'/>)}
            
            <div id="info-popup" className="info-popup">
                <span>
                    Your pin has been successfuly changed!
                </span>
            </div>
            <div ref={refModal} style={{position: modalPosition}} className="modal-container">   
                <button 
                    onClick={openModal.bind(this, "")}
                    className="close-btn">&times;</button>
                <div className="header">
                    <div className="title-container">
                        <span className="title1">Card Security</span>
                        <span className="subtitle1">Sensitive Card Data</span>
                    </div>
                    <div className="card-container">
                        <img 
                            id='firstCard'
                            src={process.env.PUBLIC_URL + "/Images/wolves-card-rounded.png"}
                            className="card-logo"/>
                        
                        <img 
                            src={process.env.PUBLIC_URL + "/Images/wolves-card-rounded.png"}
                            className="card-logo"/>
                    </div>
                </div>

                <div className="body">
                    <div className="input-container">
                        <input value={cardPin} type={inputType}/>
                        <div>
                            <div onClick={() => {
                                    if(inputType === "password"){
                                        setShowModalPin(true);
                                    }
                                    else{
                                        ShowPin("****");
                                    }
                                }} 
                                className="show-pin">
                                <span>{inputType === "password" ? "Reveal pin" : "Hide pin"}</span>
                            </div>

                            <div onClick={() => setShowModalCardData(true)} className='show-pin'>
                                <span>Show card data</span>
                            </div>
                        </div>
                        
                        {/* <div style={{opacity: '0.5', pointerEvents: 'none'}} onClick={() => setResetModal(true)} className="reset-pin">
                            <span>Reset pin</span>
                        </div> */}

                        <div style={{height: '24px'}}>
                            {error.length > 0 && (
                                <div className="error-message">
                                    {error}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div 
                    onClick = {() => openModal("")}
                    className="continue-btn">
                    <ContinueBtn message="Close"/>
                </div>

                {showModalPin && (
                    <PopupWithInput
                        overlayStyle={{borderRadius: '15px'}}
                        title="Pease insert your pin to show card pin"
                        confirmAction={pin => ShowPin(pin)}
                        denyAction={() => setShowModalPin(false)}/>
                )}

                {showModalCardData && (
                    <PopupWithInput
                        overlayStyle={{borderRadius: '15px'}}
                        title="Pease insert your pin to show card data"
                        confirmAction={showCardData}
                        denyAction={() => setShowModalCardData(false)}/>
                )}

                {resetModal && (
                    <PopupWithInput 
                        overlayStyle={{borderRadius: '15px'}}
                        title="Please your insert pin to reset card pin"
                        confirmAction={pin => ResetPin(pin)}
                        denyAction={() => setResetModal(false)}/>
                )}
            </div>
            <div className="overlay"/>
        </div>
    );
}