import React, {useEffect, useRef, useState } from "react";
import '../css/dropdown.css';
import Scrollbar from "react-custom-scrollbars";
import Select from 'react-select';
import { DatePickerBody, DatePickerMobile } from "./Datepicker";
import { Data } from "./utils";

export function Dropdown({header = "Test value", upText="Test Value", options = [], initialValue = null, setSelect = () => {}}) {
	const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(initialValue);
    const dropdownRef = useRef();
	const [wasToggleActivated, setToggleActive] = useState(false);
	
	useEffect(() => {
		if(initialValue !== null)
			setSelectedOption(initialValue);
	}, [initialValue])

	if(options === null || options.length === 0){
		options = ["no data"];
	}
	function toggle() { 
		setIsOpen(!isOpen); 
		setToggleActive(true);
	}
  
    const onOptionClicked = value => () => {
      setSelectedOption(value);
      setIsOpen(false);
    };

    useEffect(() => {
        function handler(event) {
            if(!dropdownRef.current?.contains(event.target)) {
                setIsOpen(false)
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [])
	
	useEffect(() =>{
		setSelect(selectedOption);
	}, [selectedOption])

	let languageStyle = () => {
        if(!selectedOption) return "";

        return isOpen ? "has-value-openned" : "has-value";
    }

	function ddArrow() {
		if(!wasToggleActivated)
			return "dd-arrow";

		return isOpen ? "dd-arrow dd-arrow-openned" : "dd-arrow dd-arrow-closed";
	};
    
	let ddHeaderTxt = selectedOption ? "dd-header-unselected dd-header-selected" : "dd-header-unselected"  
	let ddHeader = isOpen ? "dd-header dd-header-outline" : "dd-header";
	let customListHeight = options.length * 40 > 250 ? "250px" : JSON.stringify(options.length * 40) + "px"
	let customHiderHeight = options.length * 40 > 259 ? "260px" : JSON.stringify(options.length * 40 + 9) + "px"

	return(
	<div className="">
		<div className="dd-contaier" ref={dropdownRef}>
		<div className="mb-1">
			<span className="upText">{upText}</span>
		</div>
			<div className={ddHeader} onClick={toggle}>
				<div className="d-flex justify-content-center w-100 px-3 my-auto">
					<span className={ddHeaderTxt}>{selectedOption || header}</span>
					<div className={ddArrow()}/>
				</div>
			</div>

			<div className="hide" style={{display: isOpen ? "" : "none", height: customHiderHeight}}>
				{isOpen &&(
					<div 
						style={{height: customListHeight}}
						className="dd-list-container">
						<Scrollbar
							renderTrackVertical={props => <div {...props} className="track-vertical"/>}
							renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
							style={{height: '100%', width: '100%'}}>
							{options.map((option, i) => (
								<div key={i} className="dd-wrapper">
									<div className="dd-list-item-container"  onClick={onOptionClicked(option)}>	
										<li className="dd-list-item" defaultValue="Select your language">
											{option}
										</li>
									</div>
								</div>
							))}
						</Scrollbar>
					</div>
				)}
			</div>
		</div>
	</div>
  );
}

export function CustomSelect({customStyle = {}, customTheme = {}, options
							, selectedItem = null, handleChange, menuIsOpen, maxMenuHeight = "250px"}){
	return(
		<Select
			maxMenuHeight={maxMenuHeight}
			isSearchable= {false}
			styles={customStyle}
			theme={customTheme}
			value={options.find(x => x.value === selectedItem)}
			onChange={handleChange.bind(this)}
			options={options}/>
	);
}

export function DropdownBirthDaySignUp({options = ["2021", "2020", "2019", "2018", "2017", "2016", "2015"], headerValue = "", setSelected}){
	const [isOpen, setIsOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(headerValue);
	const dropdownRef = useRef();

	function toggle(){
		setIsOpen(!isOpen);
	}

	const chageSelectedItem = item => () =>{
		setSelected(item);
		setSelectedItem(item);
	}

	useEffect(() => {
        function handler(event) {
            if(!dropdownRef.current?.contains(event.target)) {
                setIsOpen(false)
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [])


	let arrowStyle = isOpen ? "dropdown2-arrow-opened" : "dropdown2-arrow-closed" 
	let borderColor = isOpen ? "black" : "#fdb913";
	let headerTextValue = selectedItem != headerValue ? "black" : '#c9cacc';

	return(
		<div style={{borderColor: borderColor}} className="dropdown2-container">
			<div
				ref={dropdownRef}
				onClick={toggle}
				className="dropdown2-header">
				<span style={{color: headerTextValue}}>{selectedItem}</span>
				<img 
					className={arrowStyle}
					src={process.env.PUBLIC_URL + "/Images/Icons/collapse-arrow.png"}/>
			</div>
			
			{isOpen && (
				<div className="dropdown2-list">
					<Scrollbar 
						renderTrackVertical={props => <div {...props} className="track-vertical"/>}
						renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
						width="100%">
						{options.map((option, i) => (
							<div
								onClick={chageSelectedItem(option)} 
								key={i} className="dropdown2-item">
								<span>{option}</span>
							</div>
						))}
					</Scrollbar>
				</div>
			)}
		</div>
	);
}

export function DropdownWithSimpleArrow({option =["test", "test", "test", "test", "test"],
 setSelected = () => {},refPassed, headerValue="test", headerFontSize="16px"}){
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() =>{
		function handler(event) {
            if(!refPassed.current?.contains(event.target)) {
                setIsOpen(false)
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
	}, [])

	return(
		<div ref={refPassed} className="dropdown-simple-arrow">
			<div onClick={() => setIsOpen(!isOpen)} className="header">
				<div>
					<span style={{fontSize: headerFontSize}}>{headerValue}</span>
				</div>
				<div>
					<img src={process.env.PUBLIC_URL + "/Images/Icons/collapse-arrow.png"}/>
				</div>
			</div>
			
			{isOpen && (
				<div className="dd-list">
					{
						option.map(item => (
							<div
								onClick={() => { setSelected(item); setIsOpen(false)}}
								className="dd-item">
								<span>{item}</span>
							</div>
						))
					}
				</div>
			)}
		</div>
	);
}

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const options = ["Last day", "Last week", "Last two weeks", "Last month", "Select another inverval"];
const noDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export function DropdownDateSelector({range, top="-290px", right="unset", left="unset", id,
			 headerFontSize = null, setRange = () => {}}){
	const date = new Date();

	const [isDDOpen, setIsDDOpen] = useState(false);
	const [isPickerOpen, setIsPickerOpen] = useState(false);
	const [selectedDateInput, setSelectedDateInput] = useState("");

	const ref = useRef();

	useEffect(() => {
		if(range.length === 2){
			let date1 = ""
			let date2 = ""
			let monthIndex = months.indexOf(range[0].selectedMonth) + 1 < 10 ? "0" + (months.indexOf(range[0].selectedMonth) + 1).toString()
									 : months.indexOf(range[0].selectedMonth) + 1;

			let dayAux = range[0].selectedDay < 10 ? "0" + range[0].selectedDay : range[0].selectedDay
			date1 = dayAux + "/" + monthIndex + "/" + range[0].selectedYear;

			monthIndex = months.indexOf(range[1].selectedMonth) + 1 < 10 ? "0" + (months.indexOf(range[1].selectedMonth) + 1).toString()
								: months.indexOf(range[1].selectedMonth) + 1;

			dayAux = range[1].selectedDay < 10 ? "0" + range[1].selectedDay : range[1].selectedDay
			date2 = dayAux + "/" + monthIndex + "/" + range[1].selectedYear;
			setSelectedDateInput(date1 + " - " + date2);
		}
		else if(range.length === 1){
			let date1 = ""
			let monthIndex = months.indexOf(range[0].selectedMonth) + 1 < 10 ? "0" + (months.indexOf(range[0].selectedMonth) + 1).toString()
								: months.indexOf(range[0].selectedMonth) + 1;
			
			let dayAux = range[0].selectedDay < 10 ? "0" + range[0].selectedDay : range[0].selectedDay
			date1 = dayAux + "/" + monthIndex + "/" + range[0].selectedYear;
			setSelectedDateInput(date1 + " - " + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear());
		}
	}, [range])

	function CalculatePastDate(isPastMonth, pastNoDays){
		const currentYear = date.getFullYear();
		const currentMonth = date.getMonth() + 1;
		const currentDay = date.getDate();

		let pastYear = 0;
		let pastMonth = 0;
		let pastDay = 0;

		if(isPastMonth){
			if(currentMonth === 1){
				pastYear = currentYear - 1;
				pastMonth = 12;
				pastDay = currentDay;
			}
			else{
				pastYear = currentYear;
				pastMonth = currentMonth - 1;
				pastDay = currentDay;
			}
		}
		else{
			if(currentDay - pastNoDays < 1){
				if(currentMonth === 1){
					pastYear = currentYear - 1;
					pastMonth = 12;
					pastDay = noDaysInMonth[currentMonth - 1] - (pastNoDays - currentDay);
				}
				else{
					pastYear = currentYear;
					pastMonth = currentMonth - 1;
					pastDay = noDaysInMonth[currentMonth - 2] - (pastNoDays - currentDay);
				}
			}
			else{
				pastYear = currentYear;
				pastMonth = currentMonth;
				pastDay = currentDay - pastNoDays;
			}
		}
		setRange([
			{
				selectedDay: pastDay,
				selectedMonth: months[pastMonth - 1],
				selectedYear: pastYear
			},
			{
				selectedDay: currentDay - 1,
				selectedMonth: months[currentMonth - 1],
				selectedYear: currentYear
			}
		])

		pastDay = pastDay < 10 ? "0" + pastDay : pastDay;
		pastMonth = pastMonth < 10 ? "0" + pastMonth : pastMonth;
		return pastDay + "/" + pastMonth + "/" + pastYear + " - " + (currentDay - 1) + "/" + currentMonth + "/" + currentYear;
	}

	function OnItemClick(option){
		switch(option){
			case options[0]:
				setSelectedDateInput(CalculatePastDate(false, 1));
				setIsDDOpen(false);
				break;

			case options[1]:
				setSelectedDateInput(CalculatePastDate(false, 7));
				setIsDDOpen(false);
				break;

			case options[2]:
				setSelectedDateInput(CalculatePastDate(false, 14));
				setIsDDOpen(false);
				break;

			case options[3]:
				setSelectedDateInput(CalculatePastDate(true, 1));
				setIsDDOpen(false);
				break;

			case options[4]:
				setIsPickerOpen(true);
				break;
		}
	} 

	useEffect(() => {
		setTimeout(() => {
			sessionStorage.setItem(Data.pickerState + id, isPickerOpen);
		}, 100)
	}, [isDDOpen, isPickerOpen])

	function HandleClick(event){
		if(!ref.current.contains(event.target) && sessionStorage.getItem(Data.pickerState + id) == false.toString()){
			setIsDDOpen(false);
		}
	}

	useEffect(() => {
		document.addEventListener("click", HandleClick);
		return () => document.removeEventListener("click", HandleClick);
	}, [])

	return(
		<div ref={ref} className="dd-date-container">
			<div
				onClick={() => {
					setIsDDOpen(!isDDOpen);
					setIsPickerOpen(false);
				}}
			 	className="dd-date-header">
				<img
					width = "23px" 
					height = "24px"
					src={process.env.PUBLIC_URL + "/Images/Icons/Calendar.png"}/>
				<div className="time-selected">
					<span style={{fontSize: headerFontSize}}>{selectedDateInput}</span>
				</div>
			</div>
			{isDDOpen && (
				<div className="dd-date-list">
					{options.map(option => (
						<div onClick={() => OnItemClick(option)} className="dd-date-item">
							<span>{option}</span>
						</div>
					))}
				</div>
			)}
			{isPickerOpen && (
				<div className="date-picker-body-container">
				<DatePickerBody 
					bodyTop = {top}
					bodyLeft = {left}
					bodyRight = {right}
					range = {range}
					setIsDDOpen = {setIsDDOpen.bind(this)}
					setIsPickerOpen = {setIsPickerOpen.bind(this)}
					setRange = {setRange.bind(this)}
					isRanged="true"/>
				</div>
			)}
		</div>
	);
}