import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Data } from "../Global/components/utils";
import CardPage from "./card";
import "./cardAdd.css";


export function CardAddPage(){
    const [containerHeight, setContainerHeight] = useState();
    const [opacity, setOpacity] = useState("0");
    const [minHeight, setMinHeight] = useState("370px");
    const [type, setType] = useState("");

    const container = useRef();

    function CheckHeight(){
        if(container.current !== null){
            setContainerHeight(window.innerHeight - container.current.offsetTop - 40);
            setOpacity("1");
        }

        if(window.innerWidth < 965){
            if(sessionStorage.getItem(Data.hasCard) == "false"){
                setMinHeight("560px");
            }
            else{
                setMinHeight("370px");
            }
        }
        else{
            setMinHeight("370px");
        }
    }
    
    useEffect(() => {
        var timeOut = setTimeout(() => CheckHeight());
        window.addEventListener("resize", CheckHeight);
        return () => {window.removeEventListener("resize", CheckHeight); clearTimeout(timeOut)}
    }, []);
    
    useEffect(() => {
        if(type === "virtual"){
            const elem  = document.getElementById("link-virtual").click();
        }
        else if(type === "physical"){
            const elem  = document.getElementById("link-physical").click();
        }
    }, [type]);

    let addPhysicalCardStyle = sessionStorage.getItem(Data.hasCard) == true ? 
                        {position: "relative", opacity: 0.5, pointerEvents: "none"} : {position: "relative"}
    return(
        <div style={{height: containerHeight, opacity: opacity, minHeight: minHeight}} ref={container} className="card-add-page">
            <div className="virtual-card">
                <div className="title">Virtual card</div>
                <div style={{position: 'relative'}}>
                    <img className="card-img" src={process.env.PUBLIC_URL + "/Images/wolves-card.png"}/>
                    <div onClick={() => setType("virtual")} className="add-button">
                        <img width="15" height="15" src={process.env.PUBLIC_URL + "/Images/Icons/white plus.png"}/>
                        <span>Add</span>
                    </div>
                </div>
            </div>

            {sessionStorage.getItem(Data.hasCard) == "false" && (
                <div className="real-card">
                    <div className="title">Physical card</div>
                    <div style={addPhysicalCardStyle}>
                        <img className="card-img" src={process.env.PUBLIC_URL + "/Images/wolves-card.png"}/>
                        <div onClick={() => setType("physical")} className="add-button">
                            <img width="15" height="15" src={process.env.PUBLIC_URL + "/Images/Icons/white plus.png"}/>
                            <span>Add</span>
                        </div>
                    </div>
                </div>
            )}
           
            <Link id="link-virtual" style={{position: 'absolute'}} to={{pathname: '/card/new', state: 'virtual'}}/>
            <Link id="link-physical" style={{position: 'absolute'}} to={{pathname: '/card/new', state: 'physical'}}/>

        </div>
    );
}

export function CardAddStandalone(){
    return(
        <div>
            <CardPage page="Card add"/>
        </div>
    );
}