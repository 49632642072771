import { Scrollbars } from "react-custom-scrollbars";
import CardPage from "./card";
import "./cardList.css";
import '../Dashboar/termsAndCond.css';
import { Link, useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { API, SessionVars, TokenData, TokenValidation } from "../Global/components/utils";
import axios from "axios";
import { LoadingScreen } from "../Global/components/reusable";

export function CardListPage(){
    const [objToSend, setObjToSend] = useState(null);
    const [virtualCards, setVirtualCards] = useState([]);
    const [physicalCards, setPhysicalCards] = useState([]);
    const [modalHeight, setModalHeight] = useState();
    const [needScroll, setNeedScroll] = useState();
    const [scrollHeight, setScrollHeight] = useState();
    const [modalMinHeight, setModalMinHeight] = useState("500px");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const history = useHistory();
    const ref = useRef();

    let noCards = 0;
    let spaceNeededToDisplay = 175;
    let modalHeightVar = -20;

    function HanldeClick(type, cardPan, cardId, status, token){
        let objAux = {
            type: type,
            pan: cardPan,
            id: cardId,
            status: status,
            token: token
        }

        setObjToSend(objAux);
    }

    function CheckHeight(){
        if(window.innerWidth > 1200){
            spaceNeededToDisplay = 175;
            modalHeightVar = -20;
            if(noCards != 0)
                setModalMinHeight("500px");
        }
        else{
            spaceNeededToDisplay = 450;
            if(noCards != 0){
                setModalMinHeight("680px");
                modalHeightVar = 20;
            }
        }

        if(ref != null){
            if(ref.current != null){
                if(window.innerHeight > spaceNeededToDisplay + ref.current.offsetTop + 215 * noCards || noCards == 0){
                    setNeedScroll(false);
                }
                else{
                    setNeedScroll(true);
                    console.log("what");
                    setScrollHeight(ref.current.clientHeight - spaceNeededToDisplay);
                }
                setModalHeight(window.innerHeight - ref.current.offsetTop + modalHeightVar);
            }  
        }
        
    }

    useEffect(async () => {
        var timeOut = setTimeout(() => {CheckHeight()});
        window.addEventListener("resize", CheckHeight);

        if(sessionStorage.getItem(SessionVars.hasCard)){
            setLoading(true);
            if(await TokenValidation()){
                axios.get(API.cardsUrl,{
                    headers:{
                        Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                })
                .then(res => {
                    let auxPhysical = [];
                    let auxVirtual = [];
    
                    res.data.forEach(card => {
                        if(card.status){
                            if(card.type === "Physical"){
                                auxPhysical.push(card);
                            }
                            else{
                                auxVirtual.push(card);
                            }
                        }
                    });
                    setVirtualCards(auxVirtual);
                    setPhysicalCards(auxPhysical);
                    noCards = auxVirtual.length;
                    CheckHeight();
                    setLoading(false);
                })
                .catch(err => {
                    console.log('ERROR getting card list\n' ,err.response);
                    setError("Error loading cards, please try to refresh");
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
                history.replace("/pinCheck");
            }
        }
        else{
            setPhysicalCards([]);
            setVirtualCards([]);
            noCards = 0;
            CheckHeight();
        }

        return () => {clearTimeout(timeOut); window.removeEventListener("resize", CheckHeight)}
    }, [])

    useEffect(() => {
        if(objToSend !== null){
            document.getElementById("link").click();
            setObjToSend(null);
        }
    }, [objToSend])

    return(
        <div ref={ref} style={{height: modalHeight, minHeight: modalMinHeight}} className="card-list-container">
            <Link id="link" style={{position: 'absolute'}} to={{pathname: '/card', state: objToSend}}/>
            {loading && (
                <LoadingScreen position="absolute"/>
            )}
            <div onClick={() => history.push("/cardAdd")} className="add-card">
                <img width="28px" src={process.env.PUBLIC_URL + "/Images/Icons/topup plus.png"}/>
                <div className="title">Add a card</div>
            </div>    

            {error.length > 0 ?
                <div style={{width: '100%', height: '100%', display: 'flex'}}>
                    <div style={{margin: 'auto'}} className="error-message">
                        {error}
                    </div>
                </div>
                :
                <div className="card-list-page">
                <div className="virtual-cards">
                    
                    <div className="title">Virtual Cards</div>

                    {needScroll === true && (
                        <Scrollbars
                            renderView={props => <div {...props} className="view-tc"/>}
                            renderTrackVertical={props => <div {...props} className="track-vertical-tc"/>}
                            renderThumbVertical={props => <div {...props} className="thumb-vertical-tc"/>}
                            style={{height: scrollHeight, width: '100%', overflow: 'hidden'}}>
                            {virtualCards.map(card => (
                                <div onClick={() => HanldeClick("virtual", card.truncatedPan, card.id, card.status, card.token)} className="card-list-item">
                                    <img src={process.env.PUBLIC_URL + "/Images/wolves-card.png"}/>
                                    <div className="id">{card.type} {card.truncatedPan}</div>
                                </div>
                            ))}
                        </Scrollbars>
                    )}
                    
                    {needScroll === false && (
                        <div>
                            {virtualCards.map(card => (
                                <div onClick={() => HanldeClick("virtual", card.truncatedPan, card.id, card.status, card.token)} className="card-list-item">
                                    <img src={process.env.PUBLIC_URL + "/Images/wolves-card.png"}/>
                                    <div className="id">{card.type} {card.truncatedPan}</div>
                                </div>
                            ))}
                        </div>
                    )}

                    {virtualCards.length === 0 && (
                        <div>
                            No virtual cards to display
                        </div>
                    )}
                </div>

                <div className="real-cards">
                    <div className="title">Physical Card</div>
                    {physicalCards.map(card => (
                        <div onClick={() => HanldeClick("physical", card.truncatedPan, card.id, card.status, card.token)} className="card-list-item">
                            <img src={process.env.PUBLIC_URL + "/Images/wolves-card.png"}/>
                            <div className="id">{card.type} {card.truncatedPan}</div>
                        </div>  
                    ))}
                    {physicalCards.length === 0 && (
                        <div>
                            No physical cards to display
                        </div>  
                    )}
                </div>
            </div>
            }
            
        </div>
    );
}

export function CardListStandalone(){
    return(
        <div>
            <CardPage page="Card list"/>
        </div>
    );
}