import { useState } from "react";
import { ContinueBtn, LargeInputWithTextOnTop } from "../Global/components/buttons";
import { MiniLogo, PageTitle } from "../Global/components/reusable";
import SlideShow from "../Global/components/slideshow";
import { TokenValidation } from "../Global/components/utils";
import { RightSide } from "../SignUp/emailVerification";
import './pin.css';

export default function PinPage(props){
    return(
        <div className="container-fluid row main m-0 p-0">
            <div  className="slideshow-container-main d-flex justify-content-center col-md-5 p-0 m-0">
                <SlideShow/>
            </div>
            <RightSide 
                password = {props.location.state}
                emailVerification = {false}/>
        </div>
    );
}