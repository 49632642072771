import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { ErrorWithTimer, Header, LoadingScreen, QA } from "../Global/components/reusable";
import { API } from "../Global/components/utils";
import './faq.css';

export default function FaqPage(){
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [height, setHeight] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");

    const ref = useRef();
    const ref2 = useRef();

    function HandleResize(){
        if(ref.current !== null && ref2.current !== null)
            setHeight(window.innerHeight - ref.current.offsetTop - ref2.current.offsetTop - 20);
    }

    useEffect(() => {
        var timeout = setTimeout(() => HandleResize());
        window.addEventListener("resize", HandleResize);
        return () => {clearInterval(timeout)}
    }, [])

    useEffect(() => {
        setIsLoading(true);
        axios.get(API.faqUrl)
        .then(res => {
            const data = res.data.items;
            let questionsAux = [];
            let answersAux = [];
            data.map((question, index) => {
                questionsAux.push(data[index].question);
                answersAux.push(data[index].answer);
            })
            setQuestions(questionsAux);
            setAnswers(answersAux);
            setIsLoading(false);
        })
        .catch(err => {
            setIsLoading(false);
            setError("Error loading FAQs, please try to refresh the page");
            console.log("ERROR loaing FAQs\n", err.response);
        })
    }, [])

    return(
        <div ref={ref2} className="faqpage-container">
            {isLoading && (<LoadingScreen/>)}
            
            <Header text="FAQ"/>
            <div ref={ref} style={{minHeight: height}} className="questions-container">
                <ErrorWithTimer setError={setError.bind(this)} error={error}/>
                {questions.map((q, i) =>
                    <QA 
                        id={i}
                        answer={answers[i]}
                        question={q}/>
                )}  
            </div>
        </div>
    );
}