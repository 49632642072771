import "react-modern-calendar-datepicker/lib/DatePicker.css";
import '../css/wolvesChart.css';
import { Stage, Layer, Line} from 'react-konva';
import { useEffect, useLayoutEffect, useState } from "react";
import { API, FormatCurrency, GetTransaction, inTransaction, TokenValidation } from "./utils";
import { useHistory } from "react-router-dom";

const Center = {x: 125, y: 125}
const radius = 120;
const innerRadius = radius * 0.7;
const pi = Math.PI / 3;

const Categories = [
	{
		"min" : 1,
		"max" : 1499,
		"value" : "Agricultural Services"
	},

	{
		"min" : 1500,
		"max" : 2999,
		"value" : "Contracted Services"
	},

	{
		"min" : 3000,
		"max" : 3299,
		"value" : "Airlines"
	},

	{
		"min" : 3300,
		"max" : 3499,
		"value" : "Card Rental"
	},

	{
		"min" : 3500,
		"max" : 3999,
		"value" : "Lodging"
	},

	{
		"min" : 4000,
		"max" : 4799,
		"value" : "Transportation Services"
	},

	{
		"min" : 4800,
		"max" : 4999,
		"value" : "Utility Services"
	},

	{
		"min" : 5000,
		"max" : 5599,
		"value" : "Retail Outlet Services"
	},

	{
		"min" : 5600,
		"max" : 5699,
		"value" : "Clothing Stores"
	},

	{
		"min" : 5700,
		"max" : 7299,
		"value" : "Miscellaneous Store"
	},

	{
		"min" : 7300,
		"max" : 7999,
		"value" : "Business Services"
	},

	{
		"min" : 8000,
		"max" : 8999,
		"value" : "Professional Services and Membership Organizations"
	},

	{
		"min" : 9000,
		"max" : 9999,
		"value" : "Government Services"
	}
]

const A = {x: Center.x + radius * Math.cos(0), y: Center.y + radius * Math.sin(0)};
const B = {x: Center.x + radius * Math.cos(pi), y: Center.y + radius * Math.sin(pi)};
const C = {x: Center.x + radius * Math.cos(pi * 2), y: Center.y + radius * Math.sin(pi * 2)};
const D = {x: Center.x + radius * Math.cos(pi * 3), y: Center.y + radius * Math.sin(pi * 3)};
const E = {x: Center.x + radius * Math.cos(pi * 4), y: Center.y + radius * Math.sin(pi * 4)};
const F = {x: Center.x + radius * Math.cos(pi * 5), y: Center.y + radius * Math.sin(pi * 5)};

const a = {x: Center.x + innerRadius * Math.cos(0), y: Center.y + innerRadius * Math.sin(0)};
const b = {x: Center.x + innerRadius * Math.cos(pi), y: Center.y + innerRadius * Math.sin(pi)};
const c = {x: Center.x + innerRadius * Math.cos(pi * 2), y: Center.y + innerRadius * Math.sin(pi * 2)};
const d = {x: Center.x + innerRadius * Math.cos(pi * 3), y: Center.y + innerRadius * Math.sin(pi * 3)};
const e = {x: Center.x + innerRadius * Math.cos(pi * 4), y: Center.y + innerRadius * Math.sin(pi * 4)};
const f = {x: Center.x + innerRadius * Math.cos(pi * 5), y: Center.y + innerRadius * Math.sin(pi * 5)};

const slicePercentFromSide = 0.015625;
const totalSlices = 384;
const extraSize = 0.8;

const DataAux = [10, 8, 5, 4, 3, 0.5];
const Lables = ["Food", "Clothes", "Gas", "Drinks", "Entertainment", "Gym"];
const colorArr = ["#c9cacc", "#a0cf67", "#fff453", "#fcb913", "#42c4dd", "#000000"];
const colorArrHighlited = ["#96989B", "#6E9F34", "#ECDE04", "#AD7E0C", "#1C889D", "#000001"];

const mokTransaction = [
	{amount: 10, merchantCategoryCodeDescription: 'Food'},
	{amount: 6, merchantCategoryCodeDescription: 'Food'},
	{amount: 3, merchantCategoryCodeDescription: 'Food'},
	{amount: 7, merchantCategoryCodeDescription: 'Clothes'},
	{amount: 6, merchantCategoryCodeDescription: 'Gas'},
	{amount: 13, merchantCategoryCodeDescription: 'Drinks'},
	{amount: 11, merchantCategoryCodeDescription: 'Gym'},
	{amount: 1, merchantCategoryCodeDescription: 'Entertainment'},
	{amount: 9, merchantCategoryCodeDescription: 'Entertainment'},
	{amount: 2, merchantCategoryCodeDescription: 'Makeup'},
]

export default function WolvesChart({range = []}) {
	const [sections, setSections] = useState([]);
	const [enlargeEndpoints, setEnlargeEndpoints] = useState([]);
	const [colorsToDisplay, setColorsToDisplay] = useState([]);
	const [resetPointColors, setResetPointColor] = useState([]);
	const [sumSpent, setSumSpent] = useState("");
	const [colorsLegend, setColorsLegend] = useState([]);
	const [dataIndex, setDataIndex] = useState(-1);

	const [topLabels, setTopLabels] = useState([]);
	const [Data, setData] = useState([]); 

	const history = useHistory();

	useEffect(async () => {
		const transactionsToProccess = await LoadData();
		setSumSpent("");
		if(transactionsToProccess != null)
			ProccessData(transactionsToProccess);
	}, [range])

	function ProccessData(transactionsToProccess){
		let transactions = [];
		let spendings = [];
		transactionsToProccess.forEach(transaction => {
			let categoryValue = "Unknown provider";
			Categories.forEach(category => {
				if(transaction.merchantCategoryCode == null){
					categoryValue = "IBAN transfer";
				}
				else if(parseInt(transaction.merchantCategoryCode) <= category.max 
				        && parseInt(transaction.merchantCategoryCode) >= category.min){
					categoryValue = category.value;
				}
			})

			const index = transactions.indexOf(categoryValue);
			if(index < 0){
				transactions.push(categoryValue);
				spendings.push(transaction.amount);
			}
			else{
				spendings[index] += transaction.amount;
			}
		});
		//SORT
		let topSpendings = [];
		let topTransactions = [];
		let prevMax = 999999;
		let i = 0;

		while(i < Math.min(5, spendings.length)){
			let maxVal = -1;
			let maxInd = -1;
			spendings.forEach((val, index) => {
				if(val > maxVal && val < prevMax){
					maxVal = val;
					maxInd = index;
				}
			});
			topSpendings.push(maxVal);
			topTransactions.push(transactions[maxInd]);
			
			prevMax = maxVal;
			i++;
		}
		if(i < spendings.length){
			let otherSpendings = 0;
			spendings.forEach((amount) => {
				if(amount < prevMax){
					otherSpendings += amount
				}
			})

			topSpendings.push(otherSpendings);
			if(otherSpendings == 0){
				topTransactions.push("Others (were 0£)");
			}
			else{
				topTransactions.push("Others");
			}
		}
		setData(topSpendings);
		setTopLabels(topTransactions);
	}

	function DrawHexagon(){
		let auxSections = [];
		let lastOuter = A;
		let lastInner = a;
		let i =0;

		while(i < totalSlices / 6){
			let x1 = lastInner.x - (a.x - b.x) * slicePercentFromSide;
			let y1 = lastInner.y - (a.y - b.y) * slicePercentFromSide;

			let X1 = lastOuter.x - (A.x - B.x) * slicePercentFromSide;
			let Y1 = lastOuter.y - (A.y - B.y) * slicePercentFromSide;
			
			auxSections.push([lastInner.x, lastInner.y, x1, y1 + extraSize, X1, Y1 + extraSize, lastOuter.x, lastOuter.y]);
			lastOuter = {x: X1, y: Y1};
			lastInner = {x: x1, y: y1};	
			i++;
		}

		lastOuter = B;
		lastInner = b;
		i =0;
		while(i < totalSlices / 6){
			let x1 = lastInner.x - (b.x - c.x) * slicePercentFromSide;
			let y1 = lastInner.y - (b.y - c.y) * slicePercentFromSide;

			let X1 = lastOuter.x - (B.x - C.x) * slicePercentFromSide;
			let Y1 = lastOuter.y - (B.y - C.y) * slicePercentFromSide;
			
			auxSections.push([lastInner.x, lastInner.y, x1 - extraSize, y1, X1 - extraSize, Y1, lastOuter.x, lastOuter.y]);
			lastOuter = {x: X1, y: Y1};
			lastInner = {x: x1, y: y1};	
			i++;
		}

		lastOuter = C;
		lastInner = c;
		i =0;
		while(i < totalSlices / 6){
			let x1 = lastInner.x - (c.x - d.x) * slicePercentFromSide;
			let y1 = lastInner.y - (c.y - d.y) * slicePercentFromSide;

			let X1 = lastOuter.x - (C.x - D.x) * slicePercentFromSide;
			let Y1 = lastOuter.y - (C.y - D.y) * slicePercentFromSide;
			
			auxSections.push([lastInner.x, lastInner.y, x1, y1 - extraSize, X1, Y1 - extraSize, lastOuter.x, lastOuter.y]);
			lastOuter = {x: X1, y: Y1};
			lastInner = {x: x1, y: y1};	
			i++;
		}

		lastOuter = D;
		lastInner = d;
		i =0;
		while(i < totalSlices / 6){
			let x1 = lastInner.x - (d.x - e.x) * slicePercentFromSide;
			let y1 = lastInner.y - (d.y - e.y) * slicePercentFromSide;

			let X1 = lastOuter.x - (D.x - E.x) * slicePercentFromSide;
			let Y1 = lastOuter.y - (D.y - E.y) * slicePercentFromSide;
			
			auxSections.push([lastInner.x, lastInner.y, x1, y1 - extraSize, X1, Y1 - extraSize, lastOuter.x, lastOuter.y]);
			lastOuter = {x: X1, y: Y1};
			lastInner = {x: x1, y: y1};	
			i++;
		}

		lastOuter = E;
		lastInner = e;
		i =0;
		while(i < totalSlices / 6){
			let x1 = lastInner.x - (e.x - f.x) * slicePercentFromSide;
			let y1 = lastInner.y - (e.y - f.y) * slicePercentFromSide;

			let X1 = lastOuter.x - (E.x - F.x) * slicePercentFromSide;
			let Y1 = lastOuter.y - (E.y - F.y) * slicePercentFromSide;
			
			auxSections.push([lastInner.x, lastInner.y, x1 + extraSize, y1, X1 + extraSize, Y1, lastOuter.x, lastOuter.y]);
			lastOuter = {x: X1, y: Y1};
			lastInner = {x: x1, y: y1};	
			i++;
		}

		lastOuter = F;
		lastInner = f;
		i =0;
		while(i < totalSlices / 6){
			let x1 = lastInner.x - (f.x - a.x) * slicePercentFromSide;
			let y1 = lastInner.y - (f.y - a.y) * slicePercentFromSide;

			let X1 = lastOuter.x - (F.x - A.x) * slicePercentFromSide;
			let Y1 = lastOuter.y - (F.y - A.y) * slicePercentFromSide;
			
			auxSections.push([lastInner.x, lastInner.y, x1, y1 + extraSize, X1, Y1 + extraSize, lastOuter.x, lastOuter.y]);
			lastOuter = {x: X1, y: Y1};
			lastInner = {x: x1, y: y1};	
			i++;
		}
		setSections(auxSections);
	}

	function ComputeSlices(Data = []){
		let sum = 0;
		Data.forEach(x => sum += x);
		let slicesNo = [];
		Data.forEach(x => slicesNo.push(Math.round(totalSlices * (x / sum))));
		return slicesNo;
	}

	function GetColorEndpoints(color){
		let endpoints = [];
		let i = 0;
		let indexInf = 0;
		let slicesNoByColor = ComputeSlices(Data);
		while(colorArr[i] !== color){
			indexInf += slicesNoByColor[i];
			i++;
		}
		endpoints.push(indexInf);
		endpoints.push(indexInf + slicesNoByColor[i]);
		return endpoints;
	}

	function HandleClickSection(e){
		if(colorArr.includes(e.target.attrs.fill))
			setEnlargeEndpoints(GetColorEndpoints(e.target.attrs.fill));
	}

	async function LoadData(){
		if(range.length === 2){
			if(await TokenValidation()){
				let offset = 0;
				let auxList = [];
				try{
					do{
						var res = await GetTransaction(range, offset);
						offset += 100;
						res.data.forEach(trans => {
							if(inTransaction.indexOf(trans.type) < 0 && trans.amount > 0 
								&& trans.merchantCategoryCode != null && trans.status != "Declined")
								auxList.push(trans)
						})
					}while(res.data.length === 100)
		
					return auxList;
				}
				catch(err){
					console.log("ERROR getting transaction list\n", err.response !== undefined ? err.response : err);
					return null;
				}
			}
			else{
				history.replace("/pinCheck");
			}
		}
	}

	useEffect(() => {	
		let auxArr = [];
		let auxArrLegend = [];
		let color = colorsToDisplay[enlargeEndpoints[0]];
		let indexColor = colorArr.indexOf(color);
		
		if(indexColor > -1){
			setSumSpent(FormatCurrency(Data[indexColor]));
		}
		
		colorArr.forEach((color, index) => {
			if(index === indexColor){
				auxArrLegend.push(colorArrHighlited[index]);
			}
			else{
				auxArrLegend.push(color);
			}
		})

		resetPointColors.forEach((color, index) => {
			if(index >= enlargeEndpoints[0] && index < enlargeEndpoints[1]){
				auxArr.push(colorArrHighlited[indexColor]);
			}
			else{
				auxArr.push(color);
			}
		});

		setDataIndex(indexColor);
		setColorsLegend(auxArrLegend);
		setColorsToDisplay(auxArr);
	}, [enlargeEndpoints])

	useEffect(() => {
		if(topLabels.length !== 0 && Data.length !== 0){
			let slicesByColor = ComputeSlices(Data);
			let auxArr = [];
			let auxArr1 = [];
			let colorIndex = 0;

			slicesByColor.forEach((no) => {
				let i = 0;
				while(i < no){
					auxArr.push(colorArr[colorIndex]);
					i++;
				}
				colorIndex++;
			});	
			colorArr.forEach(color => auxArr1.push(color));

			setColorsToDisplay(auxArr);
			setResetPointColor(auxArr);
			setColorsLegend(auxArr1);
			DrawHexagon();
		}
	}, [topLabels, Data])

	function textStyle(index){
		if(dataIndex === index || dataIndex === -1){
			return "#000";
		}
		return "#9F9F9F";
	} 

	return(
		<div className="wolves-chart">
			
		{topLabels.length === 0 ? 
			<div style={{textAlign: 'center', fontFamily: 'FSAlbertPro', fontSize: '20px', width: '100%'}}>
				No transactions to display for this time interval
			</div>
			:
			<div className="hexagon">
				<span className="sum-spent">{sumSpent}</span> 
					<Stage width={250} height={250}>
						<Layer>	
							{sections.map((section, i) => {
								return(
								<Line
									style={{cursor: 'pointer'}}
									onTouchStart = {e => HandleClickSection(e)}
									onClick={(e) => HandleClickSection(e)}
									points={[section[0], section[1], section[2], section[3],
											section[4], section[5], section[6], section[7]]}
									fill={colorsToDisplay[i]}
									//key={i}
									closed
									x={0}
									y={0}/>
							)})}
						</Layer>
					</Stage>
			</div>
		}

			<div className="labels-container">
					{topLabels.map((label, index) => (
						<div className="label">
							<div style={{backgroundColor: colorsLegend[index]}} className="bubble"/>
							<div style={{color: textStyle(index)}} className="text-wolves">{label}</div>
						</div>
					))}
			</div>
		</div>
	);
}