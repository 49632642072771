var forge = require('node-forge');
var axios = require('axios');
var uuid = require('uuid');

const apiEndpoint = "https://public.test.meawallet.com/gps/v2";
const issuerName = "railsbank";
const apiKeyId = "79bbf950-0906-419d-a906-05615211ba76";
const apiKeySecret = "BF6FB9515BB664F9A9D6E357D7D60FD5";
const publicKey = "3082020902820200CACC0126689F853B0BBAF966DA61336CC60537BD50CE3D3F2E91CC35FEBAEF1AF1BBF0C0AE38D762C98E195BEF97CFDEF42E96BCC2A47A9F19F162705F70219773EDB392BB38D2B1FF1FB4023D59E937D488C8B451A93805B31F13809D16895A540EDC0F675710DF1F4A3396A568F0C57C4BA72B38EB6254ABD8C56F6FA33AEA0B0782AE493FC7FABDDDB57110F3BA05C1AE83CFB9C38E8ED9B62F0B852D9B1D82B26DA062EEBB5C8BAA71C3D50414C23FF1F04C857EDCF6F7D65679669AEC5B6C7EE43B1AF4E4C8D798660C144E1951077C148BC1BDF927418BA284BDD1E7C60E896811B2640C499EF7979C1CD1539EE6825F58292E54CFC488FBCFEDC42CF67857736EF3432D5CBA365D341534BF4737AFB1957A45D9AA0596F38AD1868DF8567F28BB4F47F3A8CA1176C4ACACC3EB2411DC92463E020CBBBEBB5DE0E5574AC5661FD88EAECC67F6DC7FF28F4E3443BBC17502ED6FA284F037228EC3705FB12EB733DAC1A479CD577B4E8F4C50F83EA55F109F8A9158FF6C4D7376B15F40CC11C58F184CCCB9A110FD60C121A48A854B7FFE7270F3F1D2EBB6D729AC29BE7D6CD25CDA98B06985C52C6132FDA268817EE304965893E006454AF4F83E0E6FB7B707E61A66D5682E9E4D4DDF3C55A73367DA04DAE89EBC743969EA34219F88F0496A039AE4D223E2A6511B0DF9DE7C9F71DB35FC3AF51DA09EE59B4E9AFCE06D0203010001";
const publicKeyFingerprint = "6E1D49384F41DC49E722575424FB706DB7C5EF48E18A48D426CD8DD68BDAD3D0";

function createKeyEnvelope(key, publicKey) {
  var publicKeyRsa = forge.pki.publicKeyFromPem("-----BEGIN PUBLIC KEY-----" + forge.util.encode64(forge.util.hexToBytes(publicKey)) + "-----END PUBLIC KEY-----")
  return forge.util.bytesToHex(publicKeyRsa.encrypt(forge.util.hexToBytes(key), "RSA-OAEP", { md: forge.md.sha512.create(), mgf1: { md: forge.md.sha512.create() } }));
}

function createRequestSecret(traceId, apiKeyId, apiKeySecret) {
  var text = `${traceId}#${apiKeyId}`;
  var iv = forge.util.hexToBytes('00000000000000000000000000000000');
  var key = forge.util.hexToBytes(apiKeySecret);
  var cipher = forge.cipher.createCipher('AES-CBC', key);
  cipher.start({iv: iv});
  cipher.update(forge.util.createBuffer(text));
  cipher.finish();
  return forge.util.bytesToHex(cipher.output);
}

function decryptMessage(hexKey, hexIv, cipherText) {
  var key = forge.util.hexToBytes(hexKey);
  var iv = forge.util.hexToBytes(hexIv);
  var text = forge.util.hexToBytes(cipherText);
  var decipher = forge.cipher.createDecipher('AES-CBC', key);
  decipher.start({iv: iv});
  decipher.update(forge.util.createBuffer(text));
  decipher.finish();
  return JSON.parse(decipher.output);
}

async function getCardTotp(cardId, customerPin) {
  var req = await axios.get(`https://api.banking.bss-lab.it/api/app/banking/card-secret/${cardId}/${customerPin}`,
    { headers: 
        { 'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU2MUE4MTNDQzcyRTlGM0YyRThENDhEMzdGQkI0ODYwIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2NDc0MzQ3NzYsImV4cCI6MTY3ODk3MDc3NiwiaXNzIjoiaHR0cHM6Ly9hdXRoLmJhbmtpbmcuYnNzLWxhYi5pdCIsImF1ZCI6IkJhbmtpbmdNaWRkbGV3YXJlIiwiY2xpZW50X2lkIjoiQmFua2luZ01pZGRsZXdhcmVfU3dhZ2dlciIsInN1YiI6IjM5ZmZhZjYxLWY5NzItMzNmYS1iMjkxLTk4OTEzOGNjOGM1ZiIsImF1dGhfdGltZSI6MTY0NzQyMzc2NSwiaWRwIjoibG9jYWwiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9naXZlbm5hbWUiOiJiaWxhbCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL3N1cm5hbWUiOiJpcmZhbiIsInByZWZlcnJlZF91c2VybmFtZSI6ImJpbGFsLmlyZmFuIiwiZ2l2ZW5fbmFtZSI6ImJpbGFsIiwiZmFtaWx5X25hbWUiOiJpcmZhbiIsInBob25lX251bWJlciI6Iis0NDczODQzNjI4MDAiLCJwaG9uZV9udW1iZXJfdmVyaWZpZWQiOiJGYWxzZSIsImVtYWlsIjoiYmlsYWwuaXJmYW5AaHlwZWZpbi5jb20iLCJlbWFpbF92ZXJpZmllZCI6IlRydWUiLCJuYW1lIjoiYmlsYWwuaXJmYW4iLCJzaWQiOiIwNEM0Qzg1RTlFRTc1NEI1QTAxRjI2MEQxMTAzQTI0NiIsImlhdCI6MTY0NzQzNDc3Niwic2NvcGUiOlsiQmFua2luZ01pZGRsZXdhcmUiXSwiYW1yIjpbInB3ZCJdfQ.cJOx9laIRufYPj72K1d-5x0jHRKOwWE8LT1jzV7HfBAW9xZpFobrh3B7V3kzhhzKdOm4s_0mg-gBGxR4Nf3KveB2VCbXMKMXAs4H9w4OHiqON9SemsMKx4rSjwvZIC__OLA8Td2T2viNTzJ9L-byzb65qPps8vgZGF6MR_yzERA0DvTspgmCdXtwcpkwoZYGeO43a88hFIhSeljC_msZ22S2sNvSuPz3RbgnhBsNfZzVHJ6cvx1EJJz03BHbZTfHHnnrKF7tq1Q5NlwTKtbj1pTb2lFT_shFRd-mMeG02KT1ZlezDvpcT0heZhlPBiRe4XkW_gYZds_6fUecWUchsg'
        }
    });

  if (req.status === 200) {
    return req.data;
  }
  throw "getCardTotp failed with status code " + req.status;
}

async function getCardPan(cardId, secret, encryptionKey, publicKeyFingerprint, traceId, apiKeyId, meaSecret) {
  var req = await axios.post(`${apiEndpoint}/${issuerName}/getPan`, { 
    cardId, secret, encryptionKey, publicKeyFingerprint
  }, { headers: {
    'mea-trace-id': traceId,
    'mea-api-key-id': apiKeyId,
    'mea-secret': meaSecret
} });

  if (req.status === 200) {
    return req.data;
  }

  throw "getCardPan failed with status code " + req.status;
}

export async function meaWallet() {
  var cardId = 237733595;
  var customerPin = "8d969eef6ecad3c29a3a629280e686cf0c3f5d5a86aff3ca12020c923adc6c92";

  var key = forge.random.getBytesSync(32);
  var hexKey = forge.util.bytesToHex(key);
  var traceId = uuid.v4().toString();
  var secret = createRequestSecret(traceId, apiKeyId, apiKeySecret);
  var encryptionKey = createKeyEnvelope(hexKey, publicKey);
  var cardTotp = await getCardTotp(cardId, customerPin);
  var encryptedCardData = await getCardPan(cardId, cardTotp, encryptionKey, publicKeyFingerprint, traceId, apiKeyId, secret);
  var { pan, cvv, expiry, embossname } = decryptMessage(hexKey, encryptedCardData.iv, encryptedCardData.encryptedData);
  console.log(pan, cvv, expiry, embossname);
  return {
      pan: pan,
      cvv: cvv,
      exp: expiry,
      name: embossname
  }
}

