import './card.css';
import { useState, useEffect, useRef } from 'react';
import {Header, NavigationBar, Home} from '../Dashboar/dashboar.js';
import { LinksDashboard, Popup } from '../Global/components/reusable';
import { SecurityPage, PasswordPage} from '../Dashboar/security';
import { ChangePicturePage, PersonalInfo } from '../Dashboar/personalInfo';
import FaqPage from '../Dashboar/faq';
import TermsAndCondPage from '../Dashboar/termsAndCond';
import SupportController from '../Dashboar/support';
import AccountPage from '../Dashboar/account';
import TopupPage from '../Topup/topup';
import Topup2Page from '../Topup/topup2';
import TransactionPage from '../Transaction/transaction';
import AnalyticsPage from '../Transaction/analytics';
import GiftPage from './gift';
import { NotificationPage, OffersPage, SettingsPageNotification } from '../Notification/notification';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { API, Data, FormatCurrency, SessionVars, TokenData, TokenValidation } from '../Global/components/utils';
import CardHome from './main.js';
import CardConfirm from './cardConfirm';
import CardDetails from './cardDetails';
import CardNewAddress from './cardNewAddress';
import OrderCard from './orderCard';
import CardDone from './cardDone';
import { browserHistory } from 'react-router'
import CardSecurity from './cardSecurity';
import CardSettings from './cardSettings';
import { BankInfoPage } from '../Dashboar/bankInfo';
import { CardListPage } from './cardList';
import { CardAddPage } from './cardAdd';

export default function CardPage({page = "Home", cardInfo = {}}){
    const [currentPage, setCurrentPage] = useState("Home");
    const [isNavBarOpen, setIsNavBarOpen] = useState(false);
    const [displayCardPromt, setDisplayCardPromt] = useState(false);
    const [logoutPopup, setLogoutPopup] = useState(false);
    const [username, setUsername] = useState(sessionStorage.getItem(SessionVars.username));
    const [profilePic, setProfilePic] = useState("data:image/jpeg;base64," + sessionStorage.getItem(SessionVars.profilePic));
    const [hasNoCard, setHasNoCard] = useState(true);
    const [noNotifications, setNoNotifications] = useState(-1);
    const [modal, openModal] = useState(null);
    const [deliveryAddress, setDeliveryAddress] = useState(null);
    const navBarTextRef = useRef(null);
    const history = useHistory();
    let modalPage = null;
    
    function toggleNavBar(){
        setIsNavBarOpen(!isNavBarOpen);
    }

    function closeNavBar(){
        setIsNavBarOpen(false);
    }

    function Logout(){
        const token = localStorage.getItem(TokenData.notificationToken);
        console.log("Logout\n", token);
        axios.post(API.unregisterPushNotifications, {
            token: token,
            device: "Web",
            provider: "Firebase"
        },{
            headers:{
                Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
            }
        })
        .then(res => {
            console.log("Unregistration successeded\n", res);
        })
        .catch(err => {
            console.log("ERROR unregistring from notifications failed\n", err.response);
        })
        
        localStorage.clear();
        sessionStorage.clear();
        history.entries = [];
        history.index = -1;
        history.replace('/app');
        clearTimeout(sessionStorage.getItem(SessionVars.idleTimer));
    }

    //CARD RELATED MODALS
    switch(modal){
        case "Order Card":{
            modalPage = <OrderCard openModal = {openModal.bind(this)}/>
            break;
        }

        case "Card Details":{
            modalPage = <CardDetails 
                            deliveryAddress={deliveryAddress}
                            setDeliveryAddress={setDeliveryAddress.bind(this)} 
                            openModal = {openModal.bind(this)}/>
            break;
        }

        case "Card New Address":{
            modalPage = <CardNewAddress 
                            setDeliveryAddress={setDeliveryAddress.bind(this)}
                            openModal = {openModal.bind(this)}/>
            break;
        }

        case "Card Confirm":{
            modalPage = <CardConfirm openModal = {openModal.bind(this)}/>
            break;
        }
        
        case "Card Done":{
            modalPage = <CardDone openModal = {openModal.bind(this)}/>
            break;
        }

        case "Card Security":{
            modalPage = <CardSecurity openModal = {openModal.bind(this)}/>
            break;
        }

        case "Card Settings":{
            modalPage = <CardSettings openModal = {openModal.bind(this)}/>
            break;
        }
    }

     //CHECK IF USER HAS CARD
     useEffect(async () => {
        if(await TokenValidation()){
            axios.get(API.cardsUrl, {
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                if(res.data.length === 0){
                    const date = new Date();
                    let today = date.getFullYear() + '/' + date.getMonth() + '/' + date.getDate();

                    if(localStorage.getItem(SessionVars.lastDateCardPromtShown) === null || 
                    localStorage.getItem(SessionVars.lastDateCardPromtShown) !== today){
                        localStorage.setItem(SessionVars.lastDateCardPromtShown, today);
                        setDisplayCardPromt(true);
                    }
                    setHasNoCard(true);
                    sessionStorage.setItem(Data.hasCard, "false");
                }
                else{
                    let hasPhysicalCard = false;
                    res.data.forEach(card => {
                        if(card.type === "Physical"){
                            sessionStorage.setItem(Data.cardStatus, card.status);
                            hasPhysicalCard = true;
                        }
                    })
                    setHasNoCard(hasPhysicalCard);
                    sessionStorage.setItem(Data.hasCard, hasPhysicalCard);
                }
            })
            .catch(err => {
                console.log("ERROR getting the cards list\n", err);
            })
        }
        else{
            history.replace("/pinCheck");
        }
    }, [])

    useEffect(() => {
        let ignore = false;
        async function FetchData(){
            if(!ignore){
                //CHECK FIRST IF YOU HAVE ANY DATA SAVED
                if(sessionStorage.getItem(SessionVars.userId) === null || sessionStorage.getItem(SessionVars.userId) === ""){
                    if(await TokenValidation()){
                        axios.get(API.me, {
                            headers: {
                                'Authorization': `Bearer ${sessionStorage.getItem(TokenData.token)}`
                        }
                        })
                        .then(res => {
                            setUsername(res.data.name + " " + res.data.surname);
                            sessionStorage.setItem(SessionVars.username, res.data.name + " " + res.data.surname);
                            sessionStorage.setItem(SessionVars.userId, res.data.id);
                            sessionStorage.setItem(SessionVars.hasAgreedTerms, res.data.hasAgreedTerms);
                            sessionStorage.setItem(SessionVars.hasAgreedTermsPrivacy, res.data.hasAgreedTermsPrivacy);
                            sessionStorage.setItem(SessionVars.hasAgreedMarketing, res.data.hasAgreedMarketing);
                            localStorage.setItem(SessionVars.name, res.data.name);
                            console.log("GET ME");
                            if(localStorage.getItem(SessionVars.profilePic) !== "" && localStorage.getItem(SessionVars.profilePic) !== null){
                                setProfilePic(localStorage.getItem(SessionVars.profilePic));
                            }
                            else{
                                const formatedUrl = API.getProfilePictureUrl + "/" + res.data.id;
                                axios.get(formatedUrl)
                                .then(res => {
                                    localStorage.setItem(SessionVars.profilePic, 
                                    "data:image/jpeg;base64," + res.data.fileContent);
                                    setProfilePic("data:image/jpeg;base64," + res.data.fileContent)
                                })
                                .catch(err => {
                                    console.log("Error downloading profile pic\n", err);
                                })
                            }

                        })
                        .catch(err => console.log("Get me error ", err))
                    }
                    else{
                        history.replace("/pinCheck");
                    }
                }
                else{
                    if(localStorage.getItem(SessionVars.profilePic) !== "" && localStorage.getItem(SessionVars.profilePic) !== null){
                        setProfilePic(localStorage.getItem(SessionVars.profilePic));
                    }
                    else{
                        const formatedUrl = API.getProfilePictureUrl + "/" + sessionStorage.getItem(SessionVars.userId);
                        axios.get(formatedUrl)
                        .then(res => {
                            localStorage.setItem(SessionVars.profilePic, 
                            "data:image/jpeg;base64," + res.data.fileContent);
                            setProfilePic("data:image/jpeg;base64," + res.data.fileContent)
                        })
                        .catch(err => {
                            console.log("Error downloading profile pic\n", err);
                        })
                    }
                }
            }

            
        }
        FetchData();
        return () => {ignore = true;}
    }, [])

    let navBarVisibility = isNavBarOpen ? "navigationbar-container navigationbar-container-active" : "navigationbar-container";

    return(
        <div style={{position: 'relative'}} className="container-fluid p-0">
            {logoutPopup && (
                <Popup  
                    message = "Are you sure you want to log out?"
                    closeAction = {() => setLogoutPopup(false)}
                    denyAction = {() => setLogoutPopup(false)}
                    confirmAction={() => {Logout(); setLogoutPopup(false);}}/>
            )}

            {displayCardPromt && (
                <Popup
                    closeAction={() => setDisplayCardPromt(false)}
                    denyAction={() => setDisplayCardPromt(false)}
                    confirmAction={() => {
                        var elem = document.getElementById("next");
                        if(elem)
                            elem.click();
                            
                        setDisplayCardPromt(false)
                    }}
                    message={`Order your Official Wolves Debit Card for ${ 
                            FormatCurrency(sessionStorage.getItem(SessionVars.cardPrice), sessionStorage.getItem(SessionVars.currencyType))} today.`}/>
            )}

            <Link style={{position:'absolute'}} id="next" to={{pathname: 'card/new', state: 'physical'}}/>
            {modal !== null && (
                modalPage
            )}

            <div className="cardpage-container container-fuild">
                <div className={navBarVisibility}>
                    <NavigationBar props={{setCurrentPage: setCurrentPage.bind(this),
                                            closeNavBar: closeNavBar.bind(this),
                                            setLogoutPopup: setLogoutPopup.bind(this),
                                            isNavBarOpen: isNavBarOpen,
                                            navBarTextRef: navBarTextRef,
                                            currentPage: currentPage}}/>
                </div>

                <PageController props={{pageToRender: page,
                                        navBarTextRef: navBarTextRef,
                                        isNavBarOpen: isNavBarOpen,
                                        username: username,
                                        profilePic: profilePic,
                                        hasNoCard: hasNoCard,
                                        modal: modal,
                                        cardInfo: cardInfo,
                                        noNotifications: noNotifications,
                                        openModal: openModal.bind(this),
                                        toggleNavBar: toggleNavBar.bind(this),
                                        openModal: openModal.bind(this),
                                        setHasNoCard: setHasNoCard.bind(this),
                                        setLogoutPopup: setLogoutPopup.bind(this),
                                        setProfilePic: setProfilePic.bind(this),
                                        setNoNotifications: setNoNotifications.bind(this),
                                        setDisplayCardPromt: setDisplayCardPromt.bind(this), 
                                        setCurrentPage: setCurrentPage.bind(this)}}/>
            </div>
        </div>
    );
}

function PageController({props}){
    const [linksStyle, setLinksStyle] = useState("flex");
    useEffect(() => {
        if(document.location.href.includes("home")){
            setLinksStyle("flex");
        }
        else{
            setLinksStyle("none");
        }
    }, [])

    let component = null;
    const history = useHistory();

    switch(props.pageToRender){
        case "Notification":{
            component = <NotificationPage/>;
            break;
        }

        case "Offers":{
            component = <OffersPage/>;
            break;
        }

        case "Settings":{
            component = <SettingsPageNotification/>;
            break;
        }

        case "Gift":{
            component = <GiftPage type={props.cardInfo.type} modalOpened={props.modal} openModal={props.openModal.bind(this)}/>
            break;
        }

        case "Analytics":{
            component = <AnalyticsPage/>
            break;
        }

        case "Transaction":{
            component = <TransactionPage props={{setCurrentPage: props.setCurrentPage.bind(this)}}/>
            break;
        }

        case "Topup2":{
            component = <Topup2Page props={{setCurrentPage: props.setCurrentPage.bind(this)}}/>
            break;
        }

        case "Topup":{
            component = <TopupPage props={{setCurrentPage: props.setCurrentPage.bind(this)}}/>
            break;
        }

        case "Card":{
            component = <CardHome 
                            modal ={props.modal}
                            cardInfo = {props.cardInfo}
                            openModal={props.openModal.bind(this)}/>
            break;
        }

        case "Card list":{
            component = <CardListPage/>
            break;
        }

        case "Card add":{
            component = <CardAddPage/>
            break;
        }
        
        case "Home":{
            component = <Home props={{
                            logoutPopup:props.logoutPopup,
                            noNotifications: props.noNotifications,
                            setNoNotifications: props.setNoNotifications.bind(this),
                            setLogoutPopup:props.setLogoutPopup.bind(this), 
                            setDisplayCardPromt:props.setDisplayCardPromt.bind(this),             
                            setCurrentPage: props.setCurrentPage.bind(this),
                            displayCardPromt:props.displayCardPromt}}/>
            break;
        }

        case "Security": {
            component = <SecurityPage/>
            break;
        }

        case "Password": {
            component = <PasswordPage/>
            break;
        }

        case "Personal Information": {
            component = <PersonalInfo/>
            break;
        }

        case "Personal Information Pic": {
            component = <ChangePicturePage setProfilePic={props.setProfilePic.bind(this)}/>
            break;
        }

        case "FAQ": {
            component = <FaqPage/>
            break;
        }

        case "Terms & Conditions": {
            component = <TermsAndCondPage 
                            hasAgreed={sessionStorage.getItem(SessionVars.hasAgreedTerms) === "true"}
                            endpoint={API.termsUrl}
                            agreeMessage={"I agree with terms and conditions"}
                            name="Terms & Conditions"/>
            break;
        }

        case "Privacy": {
            component = <TermsAndCondPage 
                            hasAgreed={sessionStorage.getItem(SessionVars.hasAgreedTermsPrivacy) === "true"}
                            endpoint={API.privacyUrl}
                            agreeMessage = {"I agree with privacy policy"}
                            name="Privacy"/>
            break;
        }

        case "Support":{
            component = <SupportController/>
            break;
        }

        case "Account":{
            component = <AccountPage/>
            break;
        }

        case "Bank Info":{
            component = <BankInfoPage/>
            break;
        }
    }
    return(
        <div className="main2 container-fuild">
            <Header props={{setCurrentPage: props.setCurrentPage.bind(this),
                            navBarTextRef: props.navBarTextRef,
                            isNavBarOpen: props.isNavBarOpen,
                            profilePic: props.profilePic,
                            username: props.username,
                            history: history,
                            noOfNotifications: props.noNotifications,
                            toggleNavBar: props.toggleNavBar.bind(this)}}/>

            <div style={{display: linksStyle}} className="links-container">
                <LinksDashboard 
                    setHasNoCard = {props.setHasNoCard.bind(this)}
                    onlyOne = {props.hasNoCard === true && props.pageToRender === "Home" 
                            && (sessionStorage.getItem(Data.cardStatus) === "Unknown" ||
                            sessionStorage.getItem(Data.cardStatus) === "AwaitingActivation")}
                    currentPage={props.pageToRender}/>
            </div>

            <div className="component-container">
                {component}
            </div>
        </div>
    );
}