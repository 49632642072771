import { IconTextBtnHorizontal } from '../Global/components/buttons';
import {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import './orderCardHome.css';
import { API, BankAccount, Data, GetCardId, SessionVars, TokenData, TokenValidation } from '../Global/components/utils';
import axios from 'axios';
import { PopupWithInput } from '../Global/components/reusable';


export default function OrderCardHome({openModalUp = () => {}, modal = "", cardInfo = {}}){
    const [isFreezed, setIsFreezed] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [freezedText, setFreezedText] = useState("Freeze");
    const [clipboardCopied, setClipboardCopied] = useState(false);
    const [cardToDisplay, setCardToDisplay] = useState("order\ card.png");
    const [freezPopup, setFreezePopup] = useState("");
    const [blockPopup, setBlockPopup] = useState("");
    const [minHeight, setMinHeight] = useState("unset");
    const [error, setError] = useState("");
    const [timerId, setTimerId] = useState("");
    const history = useHistory();

    async function Freeze(pin){
        if(await TokenValidation()){
            var sha = require('js-sha256').sha256;
            var encryptedPin = sha(pin);
            if(cardInfo.status === "Active"){
                axios.post(API.freezeCard, {}, {
                    params: {
                        userPin: encryptedPin,
                        cardId: cardInfo.id,
                    },
                    headers:{
                        Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    }
                })
                .then(res => {
                    setIsFreezed(true);
                    setFreezePopup(false);
                    cardInfo.status = "Suspended";
                    sessionStorage.setItem(Data.cardStatus, "Suspened");
                })
                .catch(err => {
                    console.log("ERROR freezing the card\n", err.response);
                    if(err.response.data.error?.message !== undefined){
                        setError(err.response.data.error.message);
                    }
                    else{
                        setError("Some error occured while freezing the card");
                    }
                    setFreezePopup(false);
                })
            }
            else if(cardInfo.status === "Suspended"){
                axios.post(API.activateCadUrl, {},{
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                    },
                    params:{
                        userPin: encryptedPin,
                        cardId: cardInfo.id,
                        cardToken: cardInfo.token
                    }
                })
                .then(res => {
                    setIsFreezed(false);
                    setFreezedText("Freeze");
                    cardInfo.status = "Active"
                    sessionStorage.setItem(Data.cardStatus, "Active");
                })
                .catch(err => {
                    console.log("ERROR activating the card\n", err.response);
                    if(err.response.data.error?.message !== undefined){
                        setError(err.response.data.error.message);
                    }
                    else{
                        setError("Some error occured while unfreezing the card");
                    }
                })
            }
            setFreezePopup(false);
        }
        else{
            history.replace("/pinCheck");
        }
    }

    async function Block(pin){
        if(await TokenValidation()){
            var sha = require('js-sha256').sha256;
            var encryptedPin = sha(pin);
            axios.post(API.closeCard, {}, {
                params: {
                    userPin: encryptedPin,
                    cardId: cardInfo.id,
                    stolen: false
                },
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then(res => {
                setIsBlocked(true);
                setBlockPopup(false);
                sessionStorage.setItem(Data.hasCard, "false");
            })
            .catch(err => {
                console.log("ERROR blocking the card\n", err.response);
                setError("Some error occured while blocking the card");
                setBlockPopup(false);
            })
        }
        else{
            history.replace("/pinCheck");
        }
    }

    useEffect(() => {
        if(cardInfo.status === "Suspended"){
            setIsFreezed(true);
        }
        else if(cardInfo.status === "Closed"){
            setIsBlocked(true);
            setIsFreezed(true);
        }

        if(cardInfo.status === "Suspended" || cardInfo.status === "Closed"){

        }
    }, [])

    function OpenSecurity(){
        if(!isBlocked && !isFreezed){
            openModalUp("Card Security"); 
            sessionStorage.setItem(Data.cardId, cardInfo.id)
        }
        else if(isBlocked){
            alert("Card is BLOCKED. You can't do anything with it");
        }
        else{
            alert("Card is FREEZED. You need to unfreeze card first");
        }
    }

    function OpenSettings(){
        if(!isBlocked){
            openModalUp("Card Settings"); 
            sessionStorage.setItem(Data.cardId, cardInfo.id)
        }
        else{
            alert("Card is BLOCKED. You can't do anything with it");
        }
    }

    function showCardSensitiveData(){
        if(!isBlocked){
            openModalUp("Card Data");
            sessionStorage.setItem(Data.cardId, cardInfo.id)
        }
        else{
            alert("Card is BLOCKED. You can't do anything with it");
        }
    }

    useEffect(() => {
        if(modal === "Card Settings"){
            setMinHeight("700px");
        }
        else{
            setMinHeight("unset");
        }
    }, [modal]) 

    useEffect(() => {
        if(timerId != null){
            clearTimeout(timerId);
        }
        if(error.length > 0){
            setTimerId(setTimeout(() => {
                setError("");
            }, 5000));
        }
    }, [error])

    useEffect(() => {
        if(isBlocked)
        {
            setCardToDisplay("wolves-card-blocked.png");
        }
        else if(isFreezed){
            setCardToDisplay("wolves-card-freezed.png");
            setFreezedText("Unfreeze");
        }
        else {
            setCardToDisplay("wolves-card-rounded.png");
        }
    
    }, [isBlocked, isFreezed])

    return(
        <div className="big-container">
            <div style={{minHeight : minHeight}} className="order-card-container">
                <div className="order-card-left">
                    <div style={{margin: 'auto'}}>
                        <div style={{textAlign: 'center', fontFamily:'FSAlbertPro', 
                            fontSize:'20px', marginBottom: '10px'}}>
                                {cardInfo.type.toUpperCase()}  {cardInfo.pan}
                        </div>
                        <div style={{height: "204px"}}>
                            <img width="320px" height="204px" src={process.env.PUBLIC_URL + "/Images/Icons/" + cardToDisplay}/>
                        </div>
                    </div>
                </div>

                <div className="order-card-right">
                    <div id="IconTextBtnHorizontal-1" className="icon-text-btn mt-0">
                        <IconTextBtnHorizontal
                            onClick={() => {setFreezePopup(true); sessionStorage.setItem(Data.cardId, cardInfo.id)}}
                            action={isFreezed || isBlocked}
                            icon="card freeze.png"
                            id="IconTextBtnHorizontal-1"
                            text={freezedText}/>
                    </div>

                    <div id="IconTextBtnHorizontal-2" className="icon-text-btn">
                        <IconTextBtnHorizontal
                            onClick= {() => { setBlockPopup(true); sessionStorage.setItem(Data.cardId, cardInfo.id)}}
                            action={isBlocked}
                            icon="card block.png"
                            id="IconTextBtnHorizontal-2"
                            text="Block"/>
                    </div>

                    <div id="IconTextBtnHorizontal-3" className="icon-text-btn">
                        <IconTextBtnHorizontal
                            icon="card iban.png"
                            onClick = {() => {
                                if(!isBlocked){
                                    setClipboardCopied(true);
                                    navigator.clipboard.writeText(sessionStorage.getItem(BankAccount.iban));
                                }
                                else
                                    alert("Your card is block");
                            }}
                            active={!isBlocked}
                            action={clipboardCopied}
                            id="IconTextBtnHorizontal-3"
                            extraIcon="copy clipboard.png"
                            text="Share IBAN"/>
                    </div>

                    <div id="IconTextBtnHorizontal-4" className="icon-text-btn">
                        <IconTextBtnHorizontal
                            onClick={() => OpenSecurity()}
                            icon="card security.png"
                            active={!isBlocked && !isFreezed}
                            id="IconTextBtnHorizontal-4"
                            text="Card Security"/>
                    </div>

                    <div id="IconTextBtnHorizontal-5" className="icon-text-btn">
                        <IconTextBtnHorizontal
                            onClick={() => OpenSettings()}
                            icon="card settings.png"
                            active={!isBlocked}
                            id="IconTextBtnHorizontal-5"
                            text="Card Settings"/>
                    </div>

                    <div style={{height: '24px', alignSelf: 'center'}}>
                        {error.length > 0 && (
                            <div className="error-message">
                                {error}
                            </div>
                        )}
                    </div>
                    
                    <div className="extra-space">
                        {clipboardCopied &&(
                            <div className="copy-info">
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/Images/Icons/checkmark.png"}/>
                                </div>
                                <div>
                                    <span>Your card has been succesfully copied.</span>
                                </div>
                                <div onClick={() => {setClipboardCopied(false)}}>
                                    <img width="22px" style={{cursor: 'pointer'}} src={process.env.PUBLIC_URL + "/Images/Icons/cancel.png"}/>
                                </div>
                            </div>
                        )}
                    </div>
                
                    {blockPopup && (
                        <PopupWithInput 
                            title={"Blocking your card will requiere you to purchase a new one with" + 
                            sessionStorage.getItem(SessionVars.cardPrice) +
                            "£.Please insert your pin to block your card"}
                            denyAction={() => setBlockPopup(false)}
                            confirmAction={(pin) => Block(pin)}/>
                    )}

                    {freezPopup && (
                        <PopupWithInput
                            title="Please insert your pin to continue"
                            denyAction={() => setFreezePopup(false)}
                            confirmAction={(pin) => Freeze(pin)}/>
                    )}
                </div>
            </div>
        </div>
    );
}