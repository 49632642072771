import { ContinueBtn } from '../Global/components/buttons';
import {useState, useEffect, useRef} from 'react';
import './topup2.css';
import { useHistory } from 'react-router-dom';
import { API, BankAccount, Paths , TokenData, TokenValidation, topup} from '../Global/components/utils';
import { LoadingScreen, ToastPopup } from '../Global/components/reusable';
import '../Global/css/reusable.css';
import axios from 'axios';

export default function Topup2Page({props}){
    const [amount, setAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [currency, setCurrency] = useState("gbp");
    const [height, setHeight] = useState();
    const [iframeSrc, setIframeSrc] = useState();
    const [isIframeVisilbe, setIsIFrameVisible] = useState(false);
    const [toastMessage, setToastMeassage] = useState("");
    const [toastColor, setToastColor] = useState();
    const history = useHistory();

    const ref = useRef();

    function HandleResize(){
        setHeight(window.innerHeight - ref.current.offsetTop - 30);
    }

    useEffect(() => {
        const timeout = setTimeout(() => HandleResize());
        window.addEventListener("resize", HandleResize);
        return () => { window.removeEventListener("resize", HandleResize); clearTimeout(timeout)}
    }, [])

    function makeToastVisible(){
        const toastElem = document.getElementById("topupToast");
        toastElem.classList.add("toast-popup-active")
        setTimeout(() => {
            console.log("timer out");
            toastElem.classList.remove("toast-popup-active");
            toastElem.addEventListener("transitionend", function fn(){
                console.log("transition ned")
                toastElem.removeEventListener("transitionend", fn);
                setToastMeassage(null);
            })
        }, 5000)
    }

    useEffect(() =>{
        setCurrency(sessionStorage.getItem(BankAccount.currency));

        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
		var eventer = window[eventMethod];
		var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
        
		// Listen to message from child window
        const topupEvent = e => {
            if(e?.data == "payment-iframe-opened"){
                setIsLoading(false);
            }

            if(e?.data == "payment-iframe-cancelled"){
                setIframeSrc();
                setIsIFrameVisible(false);
                setToastMeassage("Top Up cancelled!");
                setToastColor("red");

                makeToastVisible();
            }
            else if(e?.data == "payment-iframe-paid"){
                setIframeSrc();
                setIsIFrameVisible(false);
                setToastMeassage("Top Up successed!");
                setToastColor("#a0cf67");

                makeToastVisible();
            }
            else if(e?.data == "payment-iframe-declined"){
                setIframeSrc();
                setIsIFrameVisible(false);
                setToastMeassage("Top Up failed!");
                setToastColor("red");

                makeToastVisible();
            }
        }

		eventer(messageEvent,e => topupEvent(e),false);

        return () => document.removeEventListener(messageEvent, topupEvent);
    }, [])

    const topup = async() => {
        if(await TokenValidation()){
            setIsLoading(true);
            axios.post(API.topUp, {
                currency: currency.toUpperCase(),
                amount: amount,
                showCloseButton: true
            },{
                headers:{
                    Authorization: `Bearer ${sessionStorage.getItem(TokenData.token)}`
                }
            })
            .then( res => {
                setIframeSrc(res.data.iFrameUrl);
                setIsIFrameVisible(true);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err.response);
            })
        }
        else{
            history.replace("/pinCheck");
        }
    }
  
    return(
        <div ref={ref} style={{height: height}} className="topup2-container">
            {isIframeVisilbe && (
              <div class="topup-iframe-container">
                <div class="container h-100">
                  <iframe 
                      onEnded={() => setIsIFrameVisible(false)} 
                      onError={() => setIsIFrameVisible(false)} 
                      src={iframeSrc} 
                      className='topup-iframe'/>
                  </div>
              </div>
            )}

            {isLoading && (
                <LoadingScreen position='fixed'/>
            )}

            <div id="topupToast" className='toast-popup' style={{backgroundColor: toastColor}}>
                <span style={{margin: 'auto'}}>{toastMessage}</span>
            </div>

            <div className="left-container">
               <img 
                style={{margin: 'auto'}}
                width={320}
                src={process.env.PUBLIC_URL + Paths.card}/>
            </div>

            <div className="right-container">
                <div className="right-container-info">
                    <div className='sum-container-pack'>
                        <div className="sum-container">
                            {amount.length > 0 && (
                                <div style={{position: 'relative', bottom: '-1px'}}>£</div>
                            )}  
                            <input
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder="Amount to add"/>
                        </div>
                        <div className="fee-container">
                            <span>No fee</span>
                        </div>
                    </div>

                    <div className="currency-container">
                        <div className='currency'>
                            <span style={{cursor: 'default'}}>{currency.toUpperCase()}</span>
                        </div>
                        <span className="balance">
                            Balance: {sessionStorage.getItem("balance")}
                        </span>
                    </div>
                </div>  
            
                <div className='button-container'>
                    <ContinueBtn message='Next' isDisabled= {amount < 1} onClick={() => topup()}/>
                </div>
            </div>
        </div>
    );
}
